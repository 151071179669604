import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  ProgressBar,
  Row,
  Col,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardTitle, CardText } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import {
  faFile,
  faFileImage,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
let variant = "";
const Messages = () => {
  const { order_Id } = useParams();
  const [orderDeatails, setOrderDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const textareaRef = useRef(null);
  const [userProfile, setUserProfile] = useState("https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg");

  const { user, isLoading } = useUser();
  const location = useLocation();
  const validStatuses = ["استفسارات", "قيد التنفيذ"];
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const encodedData = queryParams.get("id");
  const companyName = queryParams.get("Name");

  const decodedCompanyId = JSON.parse(atob(encodedData));
  const decodedOrder_id = JSON.parse(atob(order_Id));
  const storedCompanyId = decodedCompanyId;

  const getOrder = async () => {
    //console.log("Name  ", companyName);
    const token = await user.getIdToken();
    const headers = token ? { authtoken: token } : {};
    try {
      const resp = await axios.post(
        `/api/customer/orderlist/${decodedOrder_id}`,
        { companyId: storedCompanyId },
        { headers }
      );
      const resp2 = await axios.get("/api/users", { headers });
      setUserProfile(resp2.data);
      setOrderDetails(resp.data);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };
  //useEffect
  useEffect(() => {
    if (!isLoading) {
      getOrder();
    }
  }, [order_Id, isLoading]);

  const handeComplete = async () => {
    try {
      //console.log(order_Id);
      const resp = await axios.put(
        `/api/companyuser/${decodedOrder_id}/complete`
      );
      setOrderDetails(resp.data);
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }
    const resp = await axios.get(
      `/api/admindashboard/orderlist/${decodedOrder_id}`
    );
    setOrderDetails(resp.data);
  };

  const handelAccept = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};
      console.log(decodedOrder_id);
      const resp = await axios.put(
        `/api/cutomeruser/${decodedOrder_id}/accept`,
        { companyId: storedCompanyId, companyName: companyName },
        { headers }
      );
      setOrderDetails(resp.data);
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }

    getOrder();
  };

  const handleConfirmComplete = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};
      console.log(decodedOrder_id);
      const resp = await axios.put(
        `/api/cutomeruser/${decodedOrder_id}/complete`,
        null,
        { headers }
      );
      setOrderDetails(resp.data);
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }

    getOrder();
  };
  const ConversationBox = ({ senderName, message, img, profilePic }) => {
    return (
      <div className="conversation-box mt-3">
        <div className="sender-name">
          <img
          className="profile-pic"
            src={`https://magal-albinaa.dafaalla.com/${profilePic}`}
            alt="profile"
          />
          <strong>{senderName}</strong>{" "}
        </div>
        <div className="message">{message}</div>
        <div>
          {img && (
            <a
              href={`https://magal-albinaa.dafaalla.com/${img}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ cursor: "pointer" }}
                src={`https://magal-albinaa.dafaalla.com/${img}`}
                alt="img"
                width={400}
                height={400}
              />{" "}
            </a>
          )}
        </div>
        <hr />
      </div>
    );
  };

  const handleSend = async () => {
    const formData = new FormData();
    formData.append("file", file); // إضافة الملف إلى مجموعة البيانات
    formData.append("Name", user.displayName); // إضافة اسم المستخدم
    formData.append("resciverId", decodedCompanyId); // إضافة resciverId
    formData.append("message", textareaRef.current.value); // إضافة الرسالة

    try {
      //console.log(order_Id);
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.put(
        `/api/cutomeruser/${decodedOrder_id}/sendmessage`,
        formData,
        {
          headers: {
            ...headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }

    getOrder();
    setMessage("");
    setFile(null);
    setSelectedFileName("");
    textareaRef.current.value = "";
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setSelectedFileName(file.name);
  };
  if (!isLoading) {
    if (!user) return navigate("/customerlogin");
  }

  return (
    <Container className="mt-4" style={{ widt: "70%" }}>
      {isLoading ? (
        // Show spinner while loading
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <Card>
            <div className="text-start">
              <Button
                variant="info"
                style={{ width: "100px" }}
                className="mt-2 mx-2 typing"
                onClick={() => window.print()}
              >
                <FontAwesomeIcon icon={faPrint} size="lg" />
                طباعة
              </Button>
            </div>
            <CardBody className="print-section">
              <CardTitle>{orderDeatails.userName}</CardTitle>
              <Row>
                <Col sm={6}>
                  <CardText className="mt-3">
                    <strong>الطلب : </strong> {orderDeatails.subService}
                  </CardText>
                  <CardText className="mt-3">
                    <strong>الايميل :</strong> {orderDeatails.email}
                  </CardText>
                  <CardText className="mt-3">
                    <strong>رقم الهاتف :</strong> {orderDeatails.phoneNumber}
                  </CardText>
                  <CardText className="mt-3">
                    <strong>حالة الطلب :</strong> {orderDeatails.status}
                  </CardText>
                  <CardText className="mt-3">
                    <strong>سعر مزود الخدمة :</strong>{" "}
                    {orderDeatails.CompanyPrice}
                  </CardText>
                  <CardText className="mt-3">
                    <strong>تاريخ نشر الطلب :</strong> {orderDeatails.orderDate}
                  </CardText>
                </Col>
                <Col sm={6}>
                  <CardText className="mt-3">
                    <strong>المدينة :</strong> {orderDeatails.city}
                  </CardText>
                  <CardText>
                    <strong>التاريخ:</strong> {orderDeatails.date}
                  </CardText>
                  <CardText>
                    <strong>الوقت:</strong> {orderDeatails.time}
                  </CardText>
                  <CardText>
                    <strong> طريقة الدفع : </strong>{" "}
                    {orderDeatails.paymentMethod}
                  </CardText>
                  <CardText>
                    <strong> سعرك : </strong>{" "}
                    {orderDeatails.cutomerPrice
                      ? orderDeatails.cutomerPrice
                      : "لم تحدد  سعر و اعتمدن سعر مزود الخدمة"}
                  </CardText>
                  {orderDeatails.status === "جديدة" && (
                    <div className="alert-info" role="alert">
                      <CardText>
                        <strong> في انتظار موافقة أحد مزودي الخدمات</strong>
                      </CardText>
                    </div>
                  )}
                  {orderDeatails.status === "استفسارات" && (
                    <div>
                      <div className="alert-danger" role="alert">
                        <CardText>
                          {!orderDeatails.cusConfirmation && (
                            <strong>
                              {" "}
                              هذة مساحة للنقاش حول الخدمة قبل البدء يمكنك مناقشة
                              مزود الخدمة و بعد التأكد من طلبك اضغط على تأكيد
                              الطلب
                            </strong>
                          )}
                        </CardText>
                      </div>
                      <div className="alert-success" role="alert">
                        <CardText>
                          {orderDeatails.cusConfirmation && (
                            <strong>
                              {" "}
                              لقد تم تأكيد طلبك سيبدأ مزود الخدمة في التنفيذ
                            </strong>
                          )}
                        </CardText>
                      </div>
                    </div>
                  )}

                  {orderDeatails.status === "قيد التنفيذ" && (
                    <div>
                      <strong> مستوى التقدم: </strong>
                      <ProgressBar
                        fade="true"
                        now={orderDeatails.progress}
                        label={`${orderDeatails.progress}%`}
                        style={{ height: "30px" }}
                        variant={variant}
                      />
                    </div>
                  )}
                </Col>
                {orderDeatails.status === "قيد التنفيذ" &&
                  orderDeatails.CompanyComplete && (
                    <div className="alert alert-success mt-2 text-center">
                      {" "}
                      حدث مزود الخدمة البيانات بإكمال انجاز الخدمة قم بالتأكيد
                      على ذلك
                    </div>
                  )}

                {orderDeatails.paymentMethod === "تحويل بنكي" && (
                  <div className="alert alert-info mt-4 text-center ">
                    {" "}
                    ارفق اشعار التحويل البنكي في الرسائل
                  </div>
                )}
                {orderDeatails.status === "مكتملة" && (
                  <div>
                    <Button
                      disabled
                      variant="success"
                      type="submit"
                      className="w-100 mt-4"
                    >
                      تم انجاز الخدمة
                    </Button>
                  </div>
                )}

                {orderDeatails.status === "استفسارات" && (
                  <div>
                    <Button
                      disabled={orderDeatails.cusConfirmation}
                      onClick={handelAccept}
                      variant="success"
                      type="submit"
                      className="w-100 mt-4"
                    >
                      تأكيد الطلب
                    </Button>
                  </div>
                )}

                {orderDeatails.status === "قيد التنفيذ" &&
                  orderDeatails.CompanyComplete && (
                    <div>
                      <Button
                        onClick={handleConfirmComplete}
                        variant="success"
                        type="submit"
                        className="w-100 mt-4"
                      >
                        تأكيد انجاز الخدمة
                      </Button>
                    </div>
                  )}
              </Row>
            </CardBody>
          </Card>
          <Row className="mt-4">
            {validStatuses.includes(orderDeatails.status) && (
              <Card style={{ boxShadow: "none" }}>
                <CardBody>
                  <CardTitle className="mb-5">الرسائل</CardTitle>
                  {orderDeatails.messages &&
                    orderDeatails.messages.map((message, i) => (
                      <ConversationBox
                        key={i}
                        senderName={message.Name}
                        message={message.message}
                        img={message.fileUrl}
                        profilePic={userProfile.profileImage}
                      />
                    ))}
                  <div>
                    <Row>
                      <p>اضف رسالة او رد على رسالة</p>
                    </Row>
                    <Row>
                      <textarea
                        col={5}
                        row={20}
                        ref={textareaRef} // إضافة العنصر المرجعي هنا
                        required
                      />
                    </Row>
                    <Row>
                      <Button
                        onClick={handleSend}
                        disabled={!message && !orderDeatails.messages}
                        variant="primary"
                        type="submit"
                        className="mt-4 w-25"
                      >
                        أرسل
                      </Button>

                      <input
                        type="file"
                        onChange={handleFileChange}
                        disabled={!message && !orderDeatails.messages}
                        style={{ display: "none" }} // إخفاء العنصر الحقيقي
                        ref={inputFile} // الإشارة إلى العنصر الحقيقي باستخدام useRef
                      />

                      <Button
                        variant="info"
                        style={{ width: "60px" }}
                        className="mt-4 mx-2"
                        onClick={() => inputFile.current.click()} // النقر على الزر يؤدي إلى النقر على العنصر الحقيقي
                      >
                        <FontAwesomeIcon icon={faFile} size="lg" />
                      </Button>
                      {selectedFileName}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            )}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default Messages;

export function reviceId(id) {
  console.log(id);
}

import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Image } from "react-bootstrap";
import moment from "moment";
import "rc-slider/assets/index.css";
import { getDocs, collection ,getDoc ,doc ,updateDoc, query, where  } from "firebase/firestore";
import { db } from "../../../firebase";
import { Link ,useNavigate ,useParams} from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { v4 as uuidv4 } from "uuid";

const RealStateMembers = ({ city, searchTerm }) => {
  const [isLoad, setIsLoad] = useState(false);
  const [data, setData] = useState([]);
  const { user, isLoading } = useUser();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoad(true);
    if(!isLoading) {

        async function fetchUserDataById() {
            try {
              const docRef = doc(db, "Users", user.uid);
              const docSnapshot = await getDoc(docRef);
          
              if (docSnapshot.exists()) {
                const userData = {
                  id: docSnapshot.id,
                  ...docSnapshot.data(),
                };
                setUserData(userData);
              } else {
                console.log("No such document!");
              }
            } catch (error) {
              console.error("Error fetching document: ", error);
            }
          }
          

        async function fetchComaniesData() {
            try {
              const usersQuery = query(
                collection(db, "Users"),
                where("category", "==", "عقارات") // شرط الفلترة
              );
          
              const querySnapshot = await getDocs(usersQuery);
              
              // تحويل النتائج إلى مصفوفة من الكائنات
              const usersData = querySnapshot.docs.map((doc) => ({
                id: doc.id, 
                ...doc.data()
              }));
              
              setData(usersData);  // تعيين البيانات إلى حالة `data`
              console.log("usersData:", usersData);
              setIsLoad(false);
            } catch (error) {
              console.error("Error fetching documents: ", error);
              setIsLoad(false);
            }
          }
          


    

          fetchUserDataById();
      fetchComaniesData();
    }
    

  }, [isLoading]);

  

  const filteredData = data.filter(
    (request) => !city || request.city === city
  );

  const handleStartOrNavigateConversation = async ( companyId , reciverName) => {
    const responseId = uuidv4();
    console.log(companyId);
    try {
      const userDocRef = doc(db, "Users", user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.error("User does not exist!");
        return;
      }
  
      const userData = userDoc.data();
      let conversations = userData.conversations || [];
  
      // البحث عن المحادثة الحالية إذا كانت موجودة
      let conversation = conversations.find(
        (conv) => conv.reciverId === companyId 
      );
     
      if(conversation)
      localStorage.setItem("conversationId", conversation.id);
      
      if (!conversation) {
        const conversationId = uuidv4();
        localStorage.setItem("conversationId", conversationId);
        const initialMessage = {
          id: uuidv4(),
          senderId: user.uid,
          senderName:user.displayName,
          reciverName:reciverName,
          reciverId: companyId,
          message: `مرحبا لدي استفسار `,
          timestamp: new Date(),
        };
  
        conversation = {
          id: conversationId,
          senderId: user.uid,
          responseId: responseId,
          reciverId: companyId,
          customerId: user.uid,
          senderName:user.displayName,
          reciverName:reciverName,
          messages: [initialMessage],
        };
  
        // إضافة المحادثة الجديدة إلى قائمة المحادثات
        conversations.push(conversation);
    
        // تحديث مستند المستخدم
        await updateDoc(userDocRef, {
          conversations: conversations.length > 0 ? conversations : [] // تأكد من عدم إرسال قيم غير صالحة
        });
  
        // إضافة المحادثة الجديدة إلى مستند الشركة
        const companyDocRef = doc(db, "Users", companyId);
        const companyDoc = await getDoc(companyDocRef);
        if (companyDoc.exists()) {
          let companyConversations = companyDoc.data().conversations || [];
          companyConversations.push(conversation);
  
          await updateDoc(companyDocRef, {
            conversations: companyConversations.length > 0 ? companyConversations : [] // تأكد من عدم إرسال قيم غير صالحة
          });
        } else {
          console.error("Company document does not exist!");
        }
      }
  
      navigate(`/messages`);
    } catch (error) {
      console.error("Error handling conversation:", error);
    }
  };
  
  
  // تصفية البيانات بناءً على البحث والمدينة والفئات المحددة
 
  if (isLoad)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <Row>
    <Col md={3} sm={12}>
    
  </Col>
  <Col md={9} sm={12}>
  {filteredData.map((user) => (
    <div key={user.id} className="offer-card p-3 border rounded mb-3">
      <Row>
        <Col md={2} className="text-center">
          <Image
            src={user.profileImage || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} // رابط افتراضي إذا لم يكن هناك صورة
            alt="Profile"
            roundedCircle
            width={80}
            height={80}
            className="mb-2"
          />
        </Col>
        <Col md={7}>
          <h3 className="mb-2">
            <Link to={`/companyprofile/${user.id}`}>{user.displayName}</Link>
          </h3>
          <div className="text-muted mb-2">
            {Array.from({ length: 5 }, (_, index) => {
              return index < user.rating ? (
                <i
                  key={index}
                  className="bi bi-star-fill text-warning"
                ></i>
              ) : (
                <i key={index} className="bi bi-star text-warning"></i>
              );
            })}
            <p>{user.about}</p>
          </div>
        </Col>
        <Col className="text-start" md={3}>
          {userData ? (
            userData.type === "customer" && (
              <Button
                variant="primary"
                onClick={() =>
                  handleStartOrNavigateConversation(user.id, user.companyName)
                }
              >
                تواصل معي
              </Button>
            )
          ) : null}
        </Col>
      </Row>
    </div>
  ))}
</Col>

    </Row>
  );
};

export default RealStateMembers;

import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  FormControl,
  Form,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  faBox,
  faCalendar,
  faCalendarDays,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useUser from "../../hooks/useUser";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { jwtDecode } from "jwt-decode";
import NotFoundPage from "../NotFoundPage";
import Messages from "../customer/Messages";
import { CardBody, CardTitle, CardText } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  faFile,
  faFileImage,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";

const EmployeeTaskDetails = () => {
  const { task_Id } = useParams();
  const [task, setTask] = useState([]);
  const { user, isLoading } = useUser();
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const textareaRef = useRef(null);
  const [userProfile, setUserProfile] = useState("");

  const [loading, setLoading] = useState(false);

  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const getData = async () => {
    const token = await user.getIdToken();
    const headers = token ? { authtoken: token } : {};
    try {
      setLoading(true);
      const resp = await axios.post(
        `/api/employee/gettasksdetails`,
        { task_Id: task_Id },
        { headers }
      );
      const resp2 = await axios.get("/api/users", { headers });
      setUserProfile(resp2.data);
      const decodedToken = jwtDecode(resp.data);
      setTask(decodedToken.data);
      setLoading(false);
    } catch {
      console.log("error");
    }
  };

  const ConversationBox = ({ senderName, message, img, profilePic }) => {
    return (
      <div className="conversation-box mt-3">
        <div className="sender-name">
          <img
            className="profile-pic"
            src={`https://magal-albinaa.dafaalla.com/${profilePic}`}
            alt="profile"
          />
          <strong>{senderName}</strong>{" "}
        </div>
        <div className="message">{message}</div>
        <div>
          {img && (
            <a
              href={`https://magal-albinaa.dafaalla.com/${img}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ cursor: "pointer" }}
                src={`https://magal-albinaa.dafaalla.com/${img}`}
                alt="img"
                width={400}
                height={400}
              />{" "}
            </a>
          )}
        </div>
        <hr />
      </div>
    );
  };

const handleComplete = async ()=> {
    try {
      const token = await user.getIdToken();
      const headers = token? { authtoken: token } : {};
      const resp = await axios.put(
        `/api/employee/completetask`,
        { task_Id: task_Id,
          completeDate: new Date().toISOString().slice(0, 10)
            },
        { headers }
      );
      const decodedToken = jwtDecode(resp.data);
      setTask(decodedToken.data);

    } catch (err) {
        console.error("Error on Complete Task:", err.message);
    }
    
}

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setSelectedFileName(file.name);
  };

  const handleSend = async () => {
    const formData = new FormData();
    formData.append("file", file); // إضافة الملف إلى مجموعة البيانات
    formData.append("Name", user.displayName); // إضافة اسم المستخدم
    formData.append("taskId", task_Id); // إضافة اسم المستخدم
    formData.append("resciverId", 1); // إضافة resciverId
    formData.append("senderId", user.uid);
    formData.append("message", textareaRef.current.value); // إضافة الرسالة

    try {
      //console.log(order_Id);
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.put(`/api/employee/sendmessage`, formData, {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }

    getData();
    setMessage("");
    setFile(null);
    setSelectedFileName("");
    textareaRef.current.value = "";
  };

  useEffect(() => {
    if (!isLoading && user) {
      isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getData();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
  }, [isLoading]);



  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        {" "}
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />{" "}
      </div>
    );
  }

  return (
    <div className="container">
      <Card>
        <CardBody className="print-section">
          <Row>
            <Col sm={6}>
              <CardText className="mt-3">
                <strong>رقم المهمة : </strong> {task.taskNumber}
              </CardText>
              <CardText className="mt-3">
                <strong>العنوان : </strong> {task.title}
              </CardText>
              <CardText className="mt-3">
                <strong>حالة المهمة :</strong> {task.taskStatus}
              </CardText>
              <CardText className="mt-3">
                <strong>تاريخ اكمال المهمة :</strong> {task.completeDate ? task.completeDate : 'لم تكتمل بعد'}
              </CardText>
            </Col>
            <Col sm={6}>
              <CardText className="mt-3">
                <strong>تاريخ بداية المهمة :</strong> {task.date}
              </CardText>
              <CardText className="mt-3">
                <strong>التفاصيل :</strong> {task.details}
              </CardText>
              <CardText className="mt-3">
                <strong>الموظغ المسؤول:</strong>{" "}
                {task.employee ? task.employee.name : null}
              </CardText>
            </Col>
          </Row>
          <div>
            <Button
              disabled = {task.taskStatus === 'قيد التنفيذ' ? false : true}
              variant="success"
              onClick={() => handleComplete()}
              className="w-100 mt-4"
            >
            {task.taskStatus === 'قيد التنفيذ' ? 'تحويل المهمة الى مكتملة' : 'مكتملة'} 
            </Button>
          </div>
        </CardBody>
      </Card>

      <Row className="mt-4">
        <Card style={{ boxShadow: "none" }}>
          <CardBody>
            <CardTitle className="mb-5">المرفقات و الملاحظات</CardTitle>
            {task.messages &&
              task.messages.map((message, i) => (
                <ConversationBox
                  key={i}
                  senderName={message.Name}
                  message={message.message}
                  img={message.fileUrl}
                  profilePic={userProfile.profileImage}
                />
              ))}
            <div>
              <Row>
                <p>اضف ملاحظة او ارفق صورة</p>
              </Row>
              <Row>
                <textarea
                  col={5}
                  row={20}
                  ref={textareaRef} // إضافة العنصر المرجعي هنا
                  required
                />
              </Row>
              <Row>
                <Button
                  onClick={handleSend}
                  variant="primary"
                  type="submit"
                  className="mt-4 w-25"
                >
                  ارسال
                </Button>

                <input
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }} // إخفاء العنصر الحقيقي
                  ref={inputFile} // الإشارة إلى العنصر الحقيقي باستخدام useRef
                />

                <Button
                  variant="info"
                  style={{ width: "60px" }}
                  className="mt-4 mx-2"
                  onClick={() => inputFile.current.click()} // النقر على الزر يؤدي إلى النقر على العنصر الحقيقي
                >
                  <FontAwesomeIcon icon={faFile} size="lg" />
                </Button>
                {selectedFileName}
              </Row>
            </div>
          </CardBody>
        </Card>
      </Row>
    </div>
  );
};

export default EmployeeTaskDetails;

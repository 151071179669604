import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import moment from "moment";
import 'moment/locale/ar';
import "rc-slider/assets/index.css";
import { collection, getDocs, getDoc, doc , updateDoc} from "firebase/firestore";
import { db } from "../firebase"; // تأكد من أن لديك إعداد db في firebase.js
import useUser from "../hooks/useUser";
import Slider from "rc-slider";
import { MDBSpinner } from "mdb-react-ui-kit";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const RealEstateOffers = ({ searchTerm, city }) => {
  const [propertyType, setPropertyType] = useState("");
  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("");
  const [streetWidth, setStreetWidth] = useState([0, 50]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 1000000 }); // تحديث السعر
  const [rooms, setRooms] = useState([1, 6]); // فلتر عدد الغرف
  const [data, setData] = useState([]);
  const { user, isLoading } = useUser();
  const [userData, setUserData] = useState(null);
  const [isLoad , setIsLoad] = useState()
  const navigate = useNavigate();
  const [isLoadingConversation, setIsLoadingConversation] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      const fetchUserDataById = async () => {
        try {
          if (user) {
            const UserDoc = await getDoc(doc(db, "Users", user.uid));
            if (UserDoc.exists()) {
              setUserData(UserDoc.data());
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserDataById();
    }

    async function fetchRealStateOffers() {
        setIsLoad(true)
      try {
        const querySnapshot = await getDocs(collection(db, "RealStateOffers"));
        const requestsData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id, // Add docId to the data
        }));
        setIsLoad(false)
        setData(requestsData);
      } catch (error) {
        setIsLoad(false)
        console.error("Error fetching documents: ", error);
      }
    }

    fetchRealStateOffers();
  }, [isLoading]);

  const formatTimeAgo = (timestamp) => {
    const date = timestamp instanceof Date ? timestamp : timestamp?.toDate();
    moment.locale('ar');
    return moment(date).fromNow();
  };

  const filterOffers = () => {
    return data.filter((offer) => {
      // تصفية بناءً على المدينة
      if (city && offer.city !== city) return false;
      // تصفية بناءً على نوع العقار
      if (propertyType && offer.type !== propertyType) return false;
      // تصفية بناءً على المساحة
      if (minArea && parseFloat(offer.area) < parseFloat(minArea)) return false;
      if (maxArea && parseFloat(offer.area) > parseFloat(maxArea)) return false;
      // تصفية بناءً على عرض الشارع
      if (streetWidth && (offer.streetWidth < streetWidth[0] || offer.streetWidth > streetWidth[1])) return false;
      // تصفية بناءً على السعر
      const offerPrice = parseInt(offer.price.replace(/[^0-9]/g, ""), 10);
      if (priceRange && (offerPrice < priceRange.min || offerPrice > priceRange.max)) return false;
      // تصفية بناءً على عدد الغرف
      if (rooms && (offer.rooms < rooms[0] || offer.rooms > rooms[1])) return false;
      // تصفية بناءً على مصطلح البحث
      if (searchTerm && !offer.title.toLowerCase().includes(searchTerm.toLowerCase()) && !offer.offerDetails.toLowerCase().includes(searchTerm.toLowerCase())) return false;
      return true;
    });
  };

  const filteredOffers = filterOffers();


  const handleStartOrNavigateConversation = async (message, companyId, reciverName) => {
    const responseId = uuidv4();
    setIsLoadingConversation(true); // تعيين حالة التحميل إلى true عند بدء العملية
  
    try {
      const userDocRef = doc(db, "Users", user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.error("User does not exist!");
        return;
      }
  
      const userData = userDoc.data();
      let conversations = userData.conversations || [];
  
      // البحث عن المحادثة الحالية إذا كانت موجودة
      let conversation = conversations.find(
        (conv) => conv.reciverId === companyId
      );
  
      if (conversation) {
        localStorage.setItem("conversationId", conversation.id);
        console.log(conversation);
      }
  
      if (!conversation) {
        const conversationId = uuidv4();
        console.log("no");
        localStorage.setItem("conversationId", conversationId);
        const initialMessage = {
          id: uuidv4(),
          senderId: user.uid,
          senderName: user.displayName,
          reciverName: reciverName,
          reciverId: companyId,
          message: `مرحبا لدي استفسار بخصوص عرضك : "${message}"`,
          timestamp: new Date(),
        };
  
        conversation = {
          id: conversationId,
          senderId: user.uid,
          responseId: responseId,
          reciverId: companyId,
          customerId: user.uid,
          senderName: user.displayName,
          reciverName: reciverName,
          messages: [initialMessage],
        };
  
        // إضافة المحادثة الجديدة إلى قائمة المحادثات
        conversations.push(conversation);
  
        // تحديث مستند المستخدم
        await updateDoc(userDocRef, {
          conversations: conversations.length > 0 ? conversations : [] // تأكد من عدم إرسال قيم غير صالحة
        });
  
        // إضافة المحادثة الجديدة إلى مستند الشركة
        const companyDocRef = doc(db, "Users", companyId);
        const companyDoc = await getDoc(companyDocRef);
        if (companyDoc.exists()) {
          let companyConversations = companyDoc.data().conversations || [];
          companyConversations.push(conversation);
  
          await updateDoc(companyDocRef, {
            conversations: companyConversations.length > 0 ? companyConversations : [] // تأكد من عدم إرسال قيم غير صالحة
          });
        } else {
          console.error("Company document does not exist!");
        }
      }
  
      navigate(`/messages`);
    } catch (error) {
      console.error("Error handling conversation:", error);
    } finally {
      setIsLoadingConversation(false); // تعيين حالة التحميل إلى false بعد انتهاء العملية
    }
  };
  

  if (isLoad) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        {" "}
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />{" "}
      </div>
    );
  }

  return (
    <Row>
      <Col md={3}>
        <div className="sidebar bg-light p-4 border rounded">
          <h3 className="mb-4">فلاتر العروض العقارية</h3>

          <Form.Group controlId="city" className="mb-4">
            <Form.Label>المدينة</Form.Label>
            <Form.Control as="select" value={city} disabled>
              <option value="الرياض">الرياض</option>
              <option value="جدة">جدة</option>
              <option value="الدمام">الدمام</option>
              <option value="مكة">مكة</option>
              <option value="المدينة المنورة">المدينة المنورة</option>
              <option value="الطائف">الطائف</option>
              <option value="أبها">أبها</option>
              <option value="بريدة">بريدة</option>
              <option value="تبوك">تبوك</option>
              <option value="نجران">نجران</option>
              <option value="حائل">حائل</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="propertyType" className="mb-4">
            <Form.Label>نوع العقار</Form.Label>
            <Form.Control
              as="select"
              value={propertyType}
              onChange={(e) => setPropertyType(e.target.value)}
            >
              <option value="">اختر نوع العقار</option>
              <option value="أرض">أرض</option>
              <option value="شقة">شقة</option>
              <option value="فيلا">فيلا</option>
              <option value="بيت">بيت</option>
              <option value="مزرعة">مزرعة</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="area" className="mb-4">
            <h4>المساحة (متر مربع)</h4>
            <div className="d-flex">
              <Form.Control
                type="number"
                value={minArea}
                onChange={(e) => setMinArea(e.target.value)}
                placeholder="الحد الأدنى"
                className="me-2"
              />
              <Form.Control
                type="number"
                value={maxArea}
                onChange={(e) => setMaxArea(e.target.value)}
                placeholder="الحد الأقصى"
              />
            </div>
          </Form.Group>

          <Form.Group controlId="priceRange" className="mb-4">
            <h4>السعر (ريال)</h4>
            <div className="d-flex">
              <Form.Control
                type="number"
                value={priceRange.min}
                onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}
                placeholder="الحد الأدنى"
                className="me-2"
              />
              <Form.Control
                type="number"
                value={priceRange.max}
                onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })}
                placeholder="الحد الأقصى"
              />
            </div>
          </Form.Group>

          <Form.Group controlId="streetWidth" className="mb-4">
            <h4>عرض الشارع</h4>
            <Slider
              range
              min={0}
              max={50}
              value={streetWidth}
              step={1}
              onChange={(values) => setStreetWidth(values)}
              railStyle={{ backgroundColor: "#ddd", height: 6 }}
              trackStyle={[{ backgroundColor: "#548BF4", height: 6 }]}
              handleStyle={[
                { backgroundColor: "#548BF4", borderColor: "#548BF4" },
                { backgroundColor: "#548BF4", borderColor: "#548BF4" },
              ]}
            />
            <div className="d-flex justify-content-between mt-2">
              <span>{streetWidth[1]} متر</span>
              <span>{streetWidth[0]} متر</span>
            </div>
          </Form.Group>

          <Form.Group controlId="rooms" className="mb-4">
            <h4>عدد الغرف</h4>
            <Slider
              range
              min={1}
              max={6}
              value={rooms}
              step={1}
              marks={{ 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6+" }}
              onChange={(values) => setRooms(values)}
              railStyle={{ backgroundColor: "#ddd", height: 6 }}
              trackStyle={[{ backgroundColor: "#548BF4", height: 6 }]}
              handleStyle={[
                { backgroundColor: "#548BF4", borderColor: "#548BF4" },
                { backgroundColor: "#548BF4", borderColor: "#548BF4" },
              ]}
            />
          </Form.Group>
        </div>
      </Col>

      <Col md={9}>
        {
          filteredOffers.map((offer) => (
            <div className="border rounded">
            <div
            key={offer.docId}
            className="offer-card d-flex align-items-center p-3 mb-4"
          >
            <div className="w-75">
              <h3 className="mb-2">{offer.title}</h3>
              <p>{offer.offerDetails}</p>
              <p className="text-muted">
                <i className="bi bi-cash"></i> {offer.price} ريال |
                <i className="bi bi-arrows-fullscreen mx-2"></i> مساحة الأرض:{" "}
                {offer.area} م² |<i className="bi bi-signpost-split mx-2"></i>{" "}
                عرض الشارع: {offer.streetWidth} متر
              </p>
              <p className="text-muted">
                <span>بواسطة : {offer.publisher}</span> |
                <span>تاريخ النشر: {formatTimeAgo(offer.createdAt)}</span>
              </p>
            </div>
            <div className="text-start w-25">
              <img
                src={offer.fileURL}
                alt="Offer"
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "150px",
                  objectFit: "cover",
                }}
              />
            </div>
            
           
          </div> 
          {user && userData && userData.type === "customer" && (
            <div>
              <Button
                variant="primary"
                onClick={() =>
                  handleStartOrNavigateConversation(offer.title, offer.companyId, offer.publisher)
                }
                disabled={isLoadingConversation} // تعطيل الزر أثناء التحميل
              >
                {isLoadingConversation ? (
                  <MDBSpinner
                    style={{ width: "20px", height: "20px" }}
                    role="status"
                    className="me-2"
                  />
                ) : (
                  "تواصل معي"
                )}
              </Button>
            </div>
          )}
          
          
            </div>
           
          ))
        }
      </Col>
      
    </Row>
  );
};

export default RealEstateOffers;

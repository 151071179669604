import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import moment from "moment";
import "rc-slider/assets/index.css";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../firebase"; // تأكد من أن لديك إعداد db في firebase.js
import { useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import { MDBSpinner } from "mdb-react-ui-kit";

const EngineeringRequests = ({ searchTerm, city }) => {
  const [sortBy, setSortBy] = useState({
    latest: true, // جعل "الأحدث" الخيار الافتراضي
    mostViewed: false,
    mostReplies: false,
    activeNow: false,
  });
  const [userData, setUserData] = useState(null);

  const [selectedCategories, setSelectedCategories] = useState({
    "خدمات الرخص التجارية": false,
    "خدمات البلدية": false,
    "استشارات هندسية": false,
  });

  const [isLoad, setIsLoad] = useState(false);
  const { user, isLoading } = useUser();
  const [data, setData] = useState([]);
  
  const navigate = useNavigate();

  const formatTimeAgo = (time) => {
    return moment(time).fromNow();
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleNavigateToOrderDetails = (docId) => {
    navigate(`/orderdetails/Engineering/${docId}`); // Navigate to order details page with specific docId
  };

  useEffect(() => {
    setIsLoad(true);
    if(!isLoading) {

      const fetchUserDataById = async () => {
        try {
          if (!isLoading && user) {
            const UserDoc = await getDoc(doc(db, "Users", user.uid));
            if (UserDoc.exists()) {
              setUserData(UserDoc.data());
            } else {
              console.error("No such document!");
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
  
      fetchUserDataById()
    }
   
    async function fetchEngineeringData() {
      try {
        const querySnapshot = await getDocs(collection(db, "EngOrders"));
        const requestsData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id, // Add docId to the data
        }));
        setData(requestsData);
        setIsLoad(false);
      } catch (error) {
        console.error("Error fetching documents: ", error);
        setIsLoad(false);
      }
    }

     
    fetchEngineeringData();

  }, [isLoading]);

  // تصفية البيانات بناءً على البحث والمدينة والفئات المحددة
  const filteredRequests = data
    .filter(
      (request) =>
        request.orderTitle.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!city || request.city === city) &&
        (Object.values(selectedCategories).some((value) => value)
          ? Object.keys(selectedCategories).some(
              (category) =>
                selectedCategories[category] &&
                request.category === category
            )
          : true)
    )
    .sort((a, b) => {
      if (sortBy.latest) {
        return new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate());
      }
      if (sortBy.mostViewed) {
        return b.views - a.views;
      }
      if (sortBy.mostReplies) {
        return b.responses.length - a.responses.length;
      }
      if (sortBy.activeNow) {
        return a.status === "active" ? -1 : 1;
      }
      return 0;
    });

    if (isLoad) {
      return (
        <div className="text-center" style={{ marginTop: "300px" }}>
          {" "}
          <MDBSpinner
            style={{ width: "100px", height: "100px" }}
            grow
            role="status"
            className="me-2"
          />{" "}
        </div>
      );
    }

  return (
    <Row>
    <Col md={3} sm={12}>
    <div className="sidebar bg-light p-4 border rounded shadow-sm">
      <h3 className="mb-4 text-center border-bottom pb-2">التصنيف</h3>
  
      {/* فئة الطلبات */}
      <Form.Group controlId="categories" className="mb-4">
        <h4 className="fw-bold mb-3">فئة الطلبات</h4>
        <div className="d-flex flex-column gap-2">
          {Object.keys(selectedCategories).map((category) => (
            <Form.Check
              key={category}
              type="checkbox"
              id={category}
              label={category}
              checked={selectedCategories[category]}
              onChange={() => handleCategoryChange(category)}
            />
          ))}
        </div>
      </Form.Group>
  
      {/* المميزات */}
      <Form.Group controlId="sortBy" className="mt-4">
        <h4 className="fw-bold mb-3 d-block">المميزات</h4>
        <div className="d-flex flex-column gap-2">
          <Form.Check
            type="radio"
            id="latest"
            name="sortBy"
            label="الأحدث"
            checked={sortBy.latest}
            onChange={() =>
              setSortBy({
                latest: true,
                mostViewed: false,
                mostReplies: false,
                activeNow: false,
              })
            }
          />
          <Form.Check
            type="radio"
            id="mostViewed"
            name="sortBy"
            label="الأكثر مشاهدة"
            checked={sortBy.mostViewed}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: true,
                mostReplies: false,
                activeNow: false,
              })
            }
          />
          <Form.Check
            type="radio"
            id="mostReplies"
            name="sortBy"
            label="الأكثر ردود"
            checked={sortBy.mostReplies}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: false,
                mostReplies: true,
                activeNow: false,
              })
            }
          />
          <Form.Check
            type="radio"
            id="activeNow"
            name="sortBy"
            label="المتصلون الآن"
            checked={sortBy.activeNow}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: false,
                mostReplies: false,
                activeNow: true,
              })
            }
          />
        </div>
      </Form.Group>
    </div>
  </Col>
  

      <Col md={9} sm={12}>
        {isLoading ? (
          <p>جارٍ تحميل الطلبات...</p>
        ) : filteredRequests.length > 0 ? (
          filteredRequests.map((request) => (
            <div
              key={request.docId} // Use docId instead of id
              className="request-card p-3 border rounded mb-3"
            >
              <Row>
                <Col>
                  <h3 className="mb-2">{request.orderTitle}</h3>
                </Col>
                {userData ? userData.type === 'company' &&
                  <Col className="text-start" md={3}>
                  <Button
                    variant="primary"
                    onClick={() => handleNavigateToOrderDetails(request.docId)} // Pass docId to the handler
                  >
                    تفاصيل الطلب
                  </Button>
                </Col>
                :null}
                
              </Row>

              <p className="text-muted">
                <i className="bi bi-person-circle"></i> {request.customerName} |{" "}
                <i className="bi bi-clock mx-2"></i>{" "}
                {formatTimeAgo(request.createdAt.toDate())} |{" "}
                <i className="bi bi-reply mx-2"></i> عدد الردود:{" "}
                {request.responses.length} |{" "}
                <i className="bi bi-eye mx-2"></i> عدد المشاهدات:{" "}
                {request.views || 0}
              </p>
              <p>{request.orderDetails}</p>
            </div>
          ))
        ) : (
          <p>لا توجد طلبات مطابقة للبحث.</p>
        )}
      </Col>
    </Row>
  );
};

export default EngineeringRequests;

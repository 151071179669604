import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import useUser from "../hooks/useUser";
import { Row, Col, Card, Button, Form, ListGroup } from "react-bootstrap";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const Messenger = () => {
  const [conversationId , setConversationId] =  useState(localStorage.getItem("conversationId"));
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [message, setMessage] = useState("");
  const [reciverId, setReciverId] = useState("");
  const { user, isLoading } = useUser();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (!isLoading && user) {
      const fetchUserData = async () => {
        try {
          const userDoc = await getDoc(doc(db, "Users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserData(userData);

            const sortedConversations = userData.conversations.sort((a, b) => {
              const lastMessageA = a.messages[a.messages.length - 1]?.timestamp?.toDate();
              const lastMessageB = b.messages[b.messages.length - 1]?.timestamp?.toDate();
              return lastMessageB - lastMessageA;
            });
            setConversations(sortedConversations);

            if (conversationId) {
              const currentConv = sortedConversations.find(conv => conv.id === conversationId);
              setCurrentConversation(currentConv);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [isLoading, user, conversationId]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.clear();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handeSetCurrentConversation = async (conv) => {
    setCurrentConversation(conv);
    setConversationId(conv.id);
    if (userData.type === "company") {
      setReciverId(conv.senderId);
    } else {
      setReciverId(conv.reciverId);
    }

    // تحديث الرسائل غير المقروءة إلى مقروءة
    
    const updatedMessages = conv.messages.map(msg => ({
      ...msg,
      isReaded: msg.senderId !== user.uid ? true : false
    }));
    const updatedConversations = conversations.map(conversation =>
      conversation.id === conv.id ? { ...conversation, messages: updatedMessages } : conversation
    );
  
    setConversations(updatedConversations);

    // تحديث البيانات في Firebase
    const userDocRef = doc(db, "Users", user.uid);
    
    await updateDoc(userDocRef, { conversations: updatedConversations });
  };

  const handleSendMessage = async () => {
    if (!message.trim() || !currentConversation || !conversationId) {
      console.error("Message, currentConversation, or conversationId is missing!");
      return;
    }

    const newMessage = {
      id: uuidv4(),
      senderId: user.uid,
      reciverId: reciverId,
      message: message,
      isReaded: false,
      timestamp: new Date(),
    };

    try {
      // تحديث المحادثة للمستخدم المرسل
      const senderDocRef = doc(db, "Users", user.uid);
      const senderDoc = await getDoc(senderDocRef);

      if (senderDoc.exists()) {
        const senderData = senderDoc.data();
        const senderConversations = senderData.conversations || [];
        const senderConversationIndex = senderConversations.findIndex(conv => conv.id === conversationId);

        if (senderConversationIndex !== -1) {
          senderConversations[senderConversationIndex].messages.push(newMessage);
          await updateDoc(senderDocRef, { conversations: senderConversations });
          setCurrentConversation(prevState => ({
            ...prevState,
            messages: [...prevState.messages, newMessage]
          }));
          setMessage("");
        } else {
          console.error("Sender conversation not found!");
        }
      } else {
        console.error("Sender document not found!");
      }

      // تحديث المحادثة للمستخدم المستقبل
      const reciverDocRef = doc(db, "Users", reciverId);
      const reciverDoc = await getDoc(reciverDocRef);

      if (reciverDoc.exists()) {
        console.log(conversationId)
        const reciverData = reciverDoc.data();
        const reciverConversations = reciverData.conversations || [];
        const reciverConversationIndex = reciverConversations.findIndex(conv => conv.id === conversationId);

        if (reciverConversationIndex !== -1) {
          reciverConversations[reciverConversationIndex].messages.push(newMessage);
          await updateDoc(reciverDocRef, { conversations: reciverConversations });
        } else {
          console.error("Reciver conversation not found!");
        }
      } else {
        console.error("Reciver document not found!");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const formatTimeAgo = (timestamp) => {
    const date = timestamp instanceof Date ? timestamp : timestamp?.toDate();
    moment.locale('ar');
    return moment(date).fromNow();
  };

  return (
    <div className="container mt-4">
      <Row>
        <Col md={4} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>المحادثات</Card.Title>
              <ListGroup>
                {conversations.map((conv) => {
                  const hasUnreadMessages = conv.messages.some(msg => !msg.isReaded && msg.senderId !== user.uid);
                  return (
                    <ListGroup.Item
                      key={conv.id}
                      action
                      active={conv.id === conversationId}
                      onClick={() => handeSetCurrentConversation(conv)}
                      style={{
                        backgroundColor: hasUnreadMessages ? '#e9ecef' : '#ffffff', // اللون الرمادي الغامق إذا كانت هناك رسائل غير مقروءة
                        fontWeight: hasUnreadMessages ? 'bold' : 'normal',
                        color :   hasUnreadMessages ? 'black' : 'black',// تمييز النص إذا كانت هناك رسائل غير مقروءة
                      }}
                    >
                      {userData.type === 'company' ?
                        <div className="text-end">
                          <strong>محادثة مع:</strong> {conv.senderName}
                        </div>
                        : <div className="text-end">
                          <strong>محادثة مع:</strong> {conv.reciverName}
                        </div>
                      }
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title>{currentConversation ? `محادثة مع ${userData.type === 'company' ? currentConversation.senderName : currentConversation.reciverName}` : 'اختر محادثة'}</Card.Title>
              {currentConversation ? (
                <div>
                  <div className="message-list">
                    {currentConversation.messages && currentConversation.messages.length > 0 ? (
                      currentConversation.messages.map((msg) => (
                        <div
                          key={msg.id}
                          className={`message ${msg.senderId === user.uid ? 'sent' : 'received'}`}
                          style={{
                            backgroundColor: msg.senderId === user.uid ? '#d4edda' : '#f8d7da',
                            padding: '10px',
                            borderRadius: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          <p>{msg.message}</p>
                          <small className="text-muted">{formatTimeAgo(msg.timestamp)}</small>
                        </div>
                      ))
                    ) : (
                      <p>لا توجد رسائل في هذه المحادثة.</p>
                    )}
                  </div>
                  <Form.Group controlId="message">
                    <Form.Label>أرسل رسالة</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="mt-3"
                    onClick={handleSendMessage}
                  >
                    إرسال
                  </Button>
                </div>
              ) : (
                <p>اختر محادثة لعرض الرسائل.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Messenger;

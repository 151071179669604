import { useState } from "react"
import { Link , useNavigate } from "react-router-dom"
import React from 'react';
import {MDBContainer, MDBCol, MDBRow, MDBBtn, MDBIcon, MDBInput, MDBCheckbox } from 'mdb-react-ui-kit';
import {getAuth , signInWithEmailAndPassword} from 'firebase/auth'
import { db, getDoc, doc } from "../firebase"; 

import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Spinner,
  } from "react-bootstrap";
import axios from "axios";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  updatePhoneNumber
} from "firebase/auth";

const Login = () =>{

    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [error ,setError] = useState('')
    const [isSendingCode, setIsSendingCode] = useState(false); 
    const [resendAvailable, setResendAvailable] = useState(false);
    const [loading , setLoading] = useState(false);
    const navigate = useNavigate()
    const [userData, setUserData] = useState(null);

    const resendVerificationCode = async () => {
      setIsSendingCode(true);
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        await sendVerificationCode(user);
        
      } catch (error) {
        setError("فشلت عملية إعادة إرسال رمز التحقق");
      } finally {
        setIsSendingCode(false);
        
      }
    };

    const sendVerificationCode = async (user) => {
      setIsSendingCode(true);
      try {
        await sendEmailVerification(user);
        setError('')
        alert("تم اعادة ارسال رابط التحقق في البريد الالكتروني المكتوب قم بتأكيد الحساب من خلاله ثم عاود تسجيل الدخول")
      } catch (e) {
        setError("لقد تجاوزت الحد المسموح به لإعادة ارسال الرمز الرجاء المحاولة لاحقاً");
      } finally {
        setIsSendingCode(false);
      }
    };

    const login = async () =>{
        try {
          setLoading(true)
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email , password );
            const user = auth.currentUser;
        
            const UserDoc = await getDoc(doc(db, "Users", user.uid));
             console.log(UserDoc.data())
            if (UserDoc.exists()) {
              setUserData(UserDoc.data());
            }

         setLoading(false)
            if (user.emailVerified) {
              localStorage.setItem('type', UserDoc.data().type)
              navigate(`/`)
/*
               if(UserDoc.data().type === 'customer'){
                navigate(`/customerdashboard`);
              } else if(UserDoc.data().type === 'admin'){
                navigate(`/dashboard`);
              } else if(userData.type ==='company'){
                navigate(`/companydashboard`);
              }
               else if(userData.type ==='employee'){
                navigate(`/employeedashboard`);
              } */

            } else {
                setError("قم بتأكيد الحساب أولاً");
                setResendAvailable(true);
            }
        } catch (e) {
          setLoading(false)
            setError("البريد الالكتروني او كلمة السر غير صحيحة");
        }
     
    }

    return (
        <div>
        <MDBContainer className="p-3 my-5 h-custom">
        <MDBRow>
        <MDBCol col='10' md='6'>
        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" class="img-fluid" alt="Sample image" />
      </MDBCol>

          <MDBCol col='4' md='6'>
          <div className="d-lg-block" style={{height:"90px"}}></div>
          <Form onSubmit={login}>
          <Row className="mb-3 text-center">
          {error && <div className="alert alert-danger text-center">{error}</div>}
            <Form.Group as={Col} controlId="customerName">
              <Form.Label className="text-center">البريد الالكتروني</Form.Label>
              <Form.Control
                type="email"
                placeholder="أدخل البريد الالكتروني"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} controlId="customerName">
              <Form.Label>كلمة المرور</Form.Label>
              <Form.Control
                type="password"
                placeholder="أدخل كلمة المرور"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            </Row>
            </Form>
            
  
            <div className="d-flex justify-content-between mb-4">
              <a href="!#">هل نسيت كلمة السر؟</a>
            </div>
  
            <div className='text-end text-md-start mt-4 pt-2'>
            {resendAvailable ? (
              <MDBBtn
                type="button"
                className="mb-2 px-5 w-100 mt-2"
                size="lg"
                onClick={resendVerificationCode}
              >
                {isSendingCode ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "إعادة إرسال رابط التحقق"
                )}
              </MDBBtn>
            ) : null}

              <MDBBtn onClick={login} className="mb-0 px-5 w-100" size='lg'>{ loading ? <Spinner animation="border" size="sm" /> :'تسجيل دخول'}</MDBBtn>
              <p className="small fw-bold mt-2 pt-1 mb-2 text-end">ليس لديك حساب؟ <Link to={'/customersignup'} className="">انشاء حساب</Link></p>
            </div>
  
          </MDBCol>
       
        </MDBRow>
  
      
  
      </MDBContainer>
        </div>
    )
}

export default Login



import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebase";
import useUser from "../hooks/useUser";

const OrderDetails = () => {
  const { id, category } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const { user, isLoading } = useUser();
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDataById = async () => {
      try {
        if (!isLoading && user) {
          const UserDoc = await getDoc(doc(db, "Users", user.uid));
          if (UserDoc.exists()) {
            setUserData(UserDoc.data());
          } else {
            console.error("No such document!");
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    const fetchOrderDetails = async () => {
      try {
        if (category === 'Engineering') {
          const orderDoc = await getDoc(doc(db, "EngOrders", id));
          if (orderDoc.exists()) {
            setOrder({ id: orderDoc.id, ...orderDoc.data() });
          } else {
            console.error("No such document!");
          }
        }
        if (category === 'RealState') {
          const orderDoc = await getDoc(doc(db, "RealStateOrders", id));
          if (orderDoc.exists()) {
            setOrder({ id: orderDoc.id, ...orderDoc.data() });
          } else {
            console.error("No such document!");
          }
        }

        if (category === 'Others') {
          const orderDoc = await getDoc(doc(db, "OtherOrders", id));
          if (orderDoc.exists()) {
            setOrder({ id: orderDoc.id, ...orderDoc.data() });
          } else {
            console.error("No such document!");
          }
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };
  
   
  
    fetchUserDataById();
    fetchOrderDetails();
  }, [id, category, user, isLoading, userData]); // إضافة userData إلى قائمة التبعيات
  
  useEffect(() => {

    const markResponsesAsRead = async () => {
      if (!order || !user || !userData) return; // إضافة شرط التحقق من userData
   
      try {
       
        const updatedResponses = order.responses.map((response) => ({
          ...response,
          isReaded: true,
        }));
       
        if (category === 'Engineering') {
          await updateDoc(doc(db, "EngOrders", id), {
            responses: updatedResponses,
          });
        } else if (category === 'RealState') {
          await updateDoc(doc(db, "RealStateOrders", id), {
            responses: updatedResponses,
          });
        }
  
        setOrder((prevOrder) => ({
          ...prevOrder,
          responses: updatedResponses,
        }));
      } catch (error) {
        console.error("Error updating responses to read:", error);
      }
    };

    if (userData?.type === "customer") {
      markResponsesAsRead();
    }
  }, [loading]); // تأكد من تشغيل markResponsesAsRead فقط عندما يتغير userData
  

  

  const handleStartOrNavigateConversation = async (responseId, message , companyId , reciverName) => {
    if (!user || !order || !userData) return;

    try {
      const userDocRef = doc(db, "Users", user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.error("User does not exist!");
        return;
      }
  
      const userData = userDoc.data();
      let conversations = userData.conversations || [];
  
      // البحث عن المحادثة الحالية إذا كانت موجودة
      let conversation = conversations.find(
        (conv) => conv.customerId === user.uid 
      );
      if(conversation)
        localStorage.setItem("conversationId", conversation.id);
    
      if (!conversation) {
        const conversationId = uuidv4();
        localStorage.setItem("conversationId", conversationId);
        const initialMessage = {
          id: uuidv4(),
          senderId: user.uid,
          senderName:user.displayName,
          reciverName:reciverName,
          reciverId: companyId,
          message: `مرحبا لدي استفسار بخصوص التعليق: "${message}"`,
          timestamp: new Date(),
        };
  
        conversation = {
          id: conversationId,
          orderId: order.id,
          senderId: user.uid,
          responseId: responseId,
          reciverId: companyId,
          customerId: user.uid,
          senderName:user.displayName,
          reciverName:reciverName,
          messages: [initialMessage],
        };
  
        // إضافة المحادثة الجديدة إلى قائمة المحادثات
        conversations.push(conversation);
        console.log(conversations)
        // تحديث مستند المستخدم
        await updateDoc(userDocRef, {
          conversations: conversations.length > 0 ? conversations : [] // تأكد من عدم إرسال قيم غير صالحة
        });
  
        // إضافة المحادثة الجديدة إلى مستند الشركة
        const companyDocRef = doc(db, "Users", companyId);
        const companyDoc = await getDoc(companyDocRef);
        if (companyDoc.exists()) {
          let companyConversations = companyDoc.data().conversations || [];
          companyConversations.push(conversation);
  
          await updateDoc(companyDocRef, {
            conversations: companyConversations.length > 0 ? companyConversations : [] // تأكد من عدم إرسال قيم غير صالحة
          });
        } else {
          console.error("Company document does not exist!");
        }
      }
  
      navigate(`/messages`);
    } catch (error) {
      console.error("Error handling conversation:", error);
    }
  };
  
  const formatTimeAgo = (timestamp) => {
    const date = timestamp instanceof Date ? timestamp : timestamp?.toDate();
    moment.locale('ar');
    return moment(date).fromNow();
  };  

  const handleSendOffer = async () => {
    if (!message.trim()) return;

    const newResponse = {
      responseId: uuidv4(),
      senderName: user.displayName,
      senderId: user.uid,
      receiverName: order.customerName,
      receiverId: order.customerId,
      message: message,
      isReaded: false, // تعديل هنا
      timestamp: new Date(),
    };

    try {
      if(category === 'Engineering') {
        await updateDoc(doc(db, "EngOrders", id), {
          responses: arrayUnion(newResponse),
        });
        setOrder((prevOrder) => ({
          ...prevOrder,
          responses: [...prevOrder.responses, newResponse],
        }));
      }

      if(category === 'RealState') {
        await updateDoc(doc(db, "RealStateOrders", id), {
          responses: arrayUnion(newResponse),
        });
        setOrder((prevOrder) => ({
          ...prevOrder,
          responses: [...prevOrder.responses, newResponse],
        }));
      }
      
    } catch (error) {
      console.error("Error updating document: ", error);
    }

    setMessage("");
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!order) {
    return <div>لم يتم العثور على الطلب.</div>;
  }

  return (
    <div className="container mt-4">
      <h2 className="mb-4 ">{order.orderTitle}</h2>

      <Row>
        <Col md={8}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>تفاصيل الطلب</Card.Title>
              <Card.Text>{order.orderDetails}</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Text>
                <strong>اسم العميل:</strong> {order.customerName}
              </Card.Text>
              <Card.Text>
                <strong>حالة الطلب:</strong> {order.orderStatus}
              </Card.Text>
              <Card.Text>
                <strong>تاريخ النشر:</strong> {formatTimeAgo(order.createdAt)}
              </Card.Text>
              <Card.Text>
                <strong>عدد العروض:</strong> {order.responses.length}
              </Card.Text>
              <Card.Text>
                <strong>حالة الاتصال:</strong> {order.contactStatus}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        {userData?.type === 'customer' && order.responses?.length > 0 && (
          <Card className="mb-4">
            <Card.Body>
              <Card.Title className="mb-3">الردود</Card.Title>
              {order.responses.map((response, index) => (
                <div key={response.id}>
                  <Card.Text>
                    <div className="d-flex justify-content-between align-items-center">
                      <strong>{response.senderName}</strong>
                      <Button
                        variant="primary"
                        onClick={() => handleStartOrNavigateConversation(response.responseId, response.message , response.senderId , response.senderName)}
                      >
                        تواصل
                      </Button>
                    </div>
                    <p>{response.message}</p>
                    <small className="text-muted">{formatTimeAgo(response.timestamp)}</small>
                  </Card.Text>
                  {index < order.responses.length - 1 && <hr />}
                </div>
              ))}
            </Card.Body>
          </Card>
        )}

        {userData?.type === 'company' && order.responses?.length > 0 && (
          <Card className="mb-4">
            <Card.Body>
              <Card.Title className="mb-3">الردود</Card.Title>
              {order.responses
                .filter((response) => response.senderId === user.uid)
                .map((response, index) => (
                  <div key={response.id}>
                    <Card.Text>
                      <div className="d-flex justify-content-between align-items-center">
                        <strong>{response.senderName}</strong>
                      </div>
                      <p>{response.message}</p>
                      <small className="text-muted">{formatTimeAgo(response.timestamp)}</small>
                    </Card.Text>
                    {index < order.responses.length - 1 && <hr />}
                  </div>
                ))}
            </Card.Body>
          </Card>
        )}

        {userData?.type === "company" && (
          <Card className="mb-4">
            <Card.Body>
              <Form.Group controlId="message">
                <Form.Label>أرسل عرضك</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                className="mt-3"
                onClick={handleSendOffer}
              >
                أرسل عرضك
              </Button>
            </Card.Body>
          </Card>
        )}
      </Row>
    </div>
  );
};

export default OrderDetails;

import React, { useEffect, useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { db, collection, addDoc, serverTimestamp , getDocs , doc , getDoc} from "../../../firebase";
import useUser from "../../../hooks/useUser";

const AddOtherOffer = () => {
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("الرياض");
  const [offerrDetails, setOfferrDetails] = useState("");
  const { user, isLoading } = useUser();
  const [companyName, setCompanyName] = useState("");
  const [userData, setUserData] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [rating, setRating] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!isLoading && user) {
      setCompanyName(user.displayName);
      setCompanyId(user.uid);
  
      async function fetchUserDataById(userId) {
        try {
          const docRef = doc(db, "Users", userId);
          const docSnapshot = await getDoc(docRef);
  
          if (docSnapshot.exists()) {
            const userData = {
              id: docSnapshot.id,
              ...docSnapshot.data(),
            };
            setUserData(userData);
  
            // تأكد من تعيين قيمة التقييم هنا إذا كانت موجودة
            if (userData.rating) {
              setRating(userData.rating);
            } else {
              setRating(0); // أو تعيين قيمة افتراضية أخرى
            }
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching document: ", error);
        }
      }
  
      fetchUserDataById(user.uid);
    }
  }, [isLoading, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
     console.log(companyName)
    try {
      await addDoc(collection(db, "OtherOffers"), {
        companyId:user.uid,
        companyName:user.displayName,
        title,
        city,
        offerrDetails,
        rating,
        responses: [],
        views: 0, // عدد المشاهدات يبدأ من 0
        orderCount: 0, // عدد الطلبات على العرض يبدأ من 0
        status: "active", // يمكن تغيير الحالة لاحقاً بناءً على وقت الاتصال
        createdAt: serverTimestamp(), // وقت الإنشاء
      });

      alert("تم إنشاء العرض بنجاح!");
      setTitle("");
      setOfferrDetails("");
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">اضافة عرض جديد</h1>
      <Form onSubmit={handleSubmit} className="mx-auto p-4 border rounded shadow-sm bg-light" style={{ maxWidth: '600px' }}>
        <Form.Group controlId="companyName" className="mb-3">
          <Form.Label>اسم الشركة</Form.Label>
          <Form.Control
            readOnly
            type="text"
            placeholder="أدخل اسمك"
            value={companyName}
            required
          />
        </Form.Group>

        <Form.Group controlId="orderTitle" className="mb-3">
          <Form.Label>عنوان العرض</Form.Label>
          <Form.Control
            type="text"
            placeholder="أدخل عنوان العرض"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </Form.Group>


        <Form.Group controlId="city" className="mb-3">
          <Form.Label>المدينة</Form.Label>
          <Form.Control
            as="select"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          >
            <option value="الرياض">الرياض</option>
            <option value="جدة">جدة</option>
            <option value="مكة">مكة المكرمة</option>
            <option value="المدينة المنورة">المدينة المنورة</option>
            <option value="الدمام">الدمام</option>
            <option value="الخبر">الخبر</option>
            <option value="الطائف">الطائف</option>
            <option value="القصيم">القصيم</option>
            <option value="تبوك">تبوك</option>
            <option value="أبها">أبها</option>
            <option value="جازان">جازان</option>
            <option value="نجران">نجران</option>
            <option value="حائل">حائل</option>
            <option value="الباحة">الباحة</option>
            <option value="عرعر">عرعر</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="offerrDetails" className="mb-3">
          <Form.Label>تفاصيل العرض</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="أدخل تفاصيل العرض"
            value={offerrDetails}
            onChange={(e) => setOfferrDetails(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100" disabled={uploading}>
          {uploading ? "جارٍ رفع العرض..." : "اضافة العرض"}
        </Button>
      </Form>
    </Container>
  );
};

export default AddOtherOffer;

import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  FormControl,
  Form,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import Styles from "../../css/AdminDashBoard.module.css";
import {
  faBox,
  faCalendar,
  faCalendarDays,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useUser from "../../hooks/useUser";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { jwtDecode } from "jwt-decode";
import NotFoundPage from "../NotFoundPage";
import { getFunctions, httpsCallable } from 'firebase/functions';

const EmployeeTasksList = () => {
 
  const [tasks, setTasks] = useState([]);
  const [tasksWithDate, setTasksWithDate] = useState([]);
  const { user, isLoading } = useUser();
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filteredDayTasks, setFilteredDayTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDayTask, setSearchDayTask] = useState("");
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);

  const [taskNumber, setTaskNumber] = useState(generateUniqueTaskNumber());
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [taskStatus, setTaskStatus] = useState("جديدة");
  const [employee, setEmployee] = useState("");
  const [messages, setMessages] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [dateFrom, setDateFrom] = useState(new Date().toISOString().slice(0, 10));
  const [dateTo, setDateTo] = useState( new Date().toISOString().slice(0, 10));

  const [userData, setUserData] = useState("");
  const [showModal, setShowModal] = useState(false);
 
  function generateUniqueTaskNumber() {
    // احصل على الطابع الزمني الحالي
    const timestamp = Date.now().toString();
    // احصل على الأرقام الستة الأخيرة من الطابع الزمني
    const uniqueNumber = timestamp.slice(-6);
    return uniqueNumber;
  }

  const [loading, setLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);



  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const getData = async () => {
    const token = await user.getIdToken();
    const headers = token ? { authtoken: token } : {};
    try {
      const resp = await axios.get(`/api/users`, { headers });
        const resp2 = await axios.get(`/api/employee/gettasks`, { headers });

      setUserData(resp.data);
      const allTasks = jwtDecode(resp2.data.allTasks);
      const tasksOfDay = jwtDecode(resp2.data.tasksOfDay);
      
      setTasksWithDate(tasksOfDay.data);
      setTasks(allTasks.data);
      setEmployee({ id: userData.id, name: userData.name });
      
    } catch (e){
      console.log("error" , e.message);
    }
  };

  const addTask = async () => {
    try {
      setLoading(true); // Set loading to true when starting data fetching

      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const taskData = {
        taskNumber,
        title,
        details,
        taskStatus:'قيد التنفيذ',
        employee,
        date,
        messages,
      };

      const resp = await axios.post(`/api/employee/addtasks`, taskData, {
        headers,
      });

      const decodedToken = jwtDecode(resp.data);
      setTasks(decodedToken.data);
    } catch (error) {
      console.error("Error adding task:", error);
    } finally {
      setLoading(false); // Set loading to false when done fetching data
    }
    setShowModal(false);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = tasks.filter((task) =>
        task.taskNumber.toLowerCase().includes(searchTerm) ||
        task.title.toLowerCase().includes(searchTerm)
    );
    setFilteredTasks(filtered);
};

  const handleSearchIndayTask = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchDayTask(searchTerm);
        const filtered = tasksWithDate.filter((task) =>
            task.taskNumber.toLowerCase().includes(searchTerm) ||
            task.title.toLowerCase().includes(searchTerm)
        );
        setFilteredDayTasks(filtered);
    
   
};

const handleDone = async () => {
   
    try {
      setLoading(true); // Set loading to true when starting data fetching
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

       const resp = await axios.post(
          `/api/employee/gettaskswithdate`,
          { dateFrom, dateTo },
          { headers }
        );
      
      const decodedToken = jwtDecode(resp.data);
      setTasks(decodedToken.data);
    } catch (error) {
      console.error("Error fetching Tasks With date:", error);
    } finally {
      setLoading(false); // Set loading to false when done fetching data
    }
  };

  useEffect(() => {
    if(!isLoading)
        getData();
    if (!isLoading && user) {
      isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getData();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
  }, [isLoading]);
/*
  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }
*/
  if (usersLoading) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        {" "}
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />{" "}
      </div>
    );
  }

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="container">
      <Row className="text-center">
        <h2>المهام</h2>
      </Row>
      <Row className="mt-2">
      <h4>المهام اليومية</h4>
    </Row>
    <Row>
    <MDBBtn
      className="mt-4 w-25"
      onClick={handleShowModal}
     
    >
      اضافة مهمة
    </MDBBtn>
  </Row>
      <Row className="mt-4">
        <Col md={2}></Col>
        <Col md={8}>
          <FormControl
            type="text"
            placeholder="بحث عن طريق رقم المهمة او عنوانها..."
            onChange={handleSearchIndayTask}
            value={searchDayTask}
          />
        </Col>
        <Col md={2}></Col>
      </Row>

      <Row className="mt-5">
        <MDBTable striped>
          <MDBTableHead>
            <tr>
              <th scope="col">رقم المهمة</th>
              <th scope="col">العنوان</th>
              <th scope="col">حالة المهمة</th>
              <th scope="col">الموظف</th>
              <th scope="col">التاريخ</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            { tasksWithDate ? (searchDayTask ? filteredDayTasks : tasksWithDate).map((task, i) => (
              <tr key={task.taskNumber}>
                <th scope="row"><Link to={`/employeedashboard/taskslist/${task._id}`}>{task.taskNumber} </Link></th>
                <td>{task.title}</td>
                <td>{task.taskStatus}</td>
                <td>{task.employee.name}</td>
                <td>{task.date}</td>
              </tr>
            )):null}
          </MDBTableBody>
        </MDBTable>
      </Row>


      <Row className="mt-5">
      <h4>المهام حسب التاريخ</h4>
    </Row>
    <Row className="mt-2">
      <Col md={2}></Col>
      <Col md={8}>
        <FormControl
          type="text"
          placeholder="بحث عن طريق رقم المهمة او عنوانها..."
          onChange={handleSearch}
          value={searchTerm}
        />
      </Col>
      <Col md={2}></Col>
    </Row>
   
    <Row className="mt-4">
    <Col lg={6} md={6} sm={12}>
      <Form.Group as={Col} controlId="date">
        <Form.Label>من</Form.Label>
        <Form.Control
          type="date"
          value={dateFrom}
          onChange={(e) => setDateFrom(e.target.value)}
          required
        />
      </Form.Group>
    </Col>
    <Col lg={6} md={6} sm={12}>
      <Form.Group as={Col} controlId="date">
        <Form.Label>إلى</Form.Label>
        <Form.Control
          type="date"
          value={dateTo}
          onChange={(e) => setDateTo(e.target.value)}
          required
        />
      </Form.Group>
    </Col>
  </Row>

  <Row>
  <Col lg={4}></Col>
  <Col lg={4}>
    <MDBBtn
      className="mt-4 w-100"
      onClick={handleDone}
      disabled={loading}
    >
      {loading ? (
        <MDBSpinner grow role="status" className="me-2" />
      ) : (
        "تجهيز"
      )}
    </MDBBtn>
  </Col>
  <Col lg={4}></Col>
</Row>

  <Row className="mt-5">
        <MDBTable striped>
          <MDBTableHead>
            <tr>
              <th scope="col">رقم المهمة</th>
              <th scope="col">العنوان</th>
              <th scope="col">حالة المهمة</th>
              <th scope="col">الموظف</th>
              <th scope="col">التاريخ</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {(searchTerm ? filteredTasks : tasks).map((task, i) => (
              <tr key={task.taskNumber}>
                <th scope="row"><Link to={`/employeedashboard/taskslist/${task._id}`}>{task.taskNumber} </Link></th>
                <td>{task.title}</td>
                <td>{task.taskStatus}</td>
                <td>{task.employee.name}</td>
                <td>{task.date}</td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </Row>

     

      {/* Modal for adding task */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>إضافة مهمة جديدة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addTask}>
            <Form.Label className="mt-3">رقم المهمة </Form.Label>
            <FormControl
              type="text"
              placeholder="رقم المهمة"
              value={taskNumber}
              readOnly
            />
            <Form.Label className="mt-3">العنوان</Form.Label>
            <FormControl
              type="text"
              placeholder="العنوان"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
            <Form.Label className="mt-3">التفاصيل</Form.Label>

            <Form.Control
              as="textarea"
              rows={3}
              placeholder="التفاصيل"
              onChange={(e) => setDetails(e.target.value)}
              value={details}
            />
            <Form.Label className="mt-3">الموظف</Form.Label>
            <FormControl
              type="text"
              required
              placeholder="الموظف"
              value={userData.name}
              readOnly
            />
            <Form.Label className="mt-3">التاريخ</Form.Label>
            <FormControl
              type="text"
              required
              placeholder="التاريخ"
              onChange={(e) => setDate(e.target.value)}
              value={date}
              readOnly
            />

            <Button
              className="mt-4"
              variant="secondary"
              onClick={handleCloseModal}
            >
              إلغاء
            </Button>
            <Button
              className="mt-4 mx-2"
              type="submit"
              variant="primary"
              disabled={loading}
            >
              {loading ? (
                <MDBSpinner grow role="status" className="me-2" />
              ) : (
                "اضافة"
              )}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmployeeTasksList;

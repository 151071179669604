import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image, Nav, Card , Form, FormControl, Modal } from 'react-bootstrap';
import { FaUserTie, FaLaptopCode, FaMapMarkerAlt, FaBusinessTime } from 'react-icons/fa';

import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, where , getDocs, query , getDoc} from "firebase/firestore";

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBSpinner,
  } from 'mdb-react-ui-kit';
import useUser from '../../hooks/useUser';
import { useParams } from 'react-router-dom';

// استبدل القيم هنا بقيم التكوين الخاصة بمشروع Firebase الخاص بك
const firebaseConfig = {
  apiKey: "AIzaSyCHf1sAy7wB4CIGBw-lsaI4DaCXOXuLwNA",
  authDomain: "my-react-blog-191fa.firebaseapp.com",
  projectId: "my-react-blog-191fa",
  storageBucket: "my-react-blog-191fa.appspot.com",
  messagingSenderId: "821981323548",
  appId: "1:821981323548:web:f85ed9cf7518f6c4ba9ad9",
  measurementId: "G-JZZWK4GFWJ"
};

// تهيئة Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [userData, setUserData] = useState()
  const [offers, setOffers] = useState()
  const [currentId, setCurrentId] = useState()
  const [isLoad, setIsLoad] = useState(false)
  const { user, isLoading } = useUser();
  const { id } = useParams();


 
  useEffect(() => {
    if(!isLoading) {
      setIsLoad(true)
      const fetchUserDataById = async () => {
        try {
          if (!isLoading && user) {
            const UserDoc = await getDoc(doc(db, "Users", id));
            if (UserDoc.exists()) {
              setCurrentId(UserDoc.id)
              setUserData(UserDoc.data());
            } else {
              console.error("No such document!");
            }
            setIsLoad(false)
          }
        } catch (error) {
          setIsLoad(false)
          console.error("Error fetching user data:", error);
        }
      };
      const fetchOffers = async () => {
        try {
          if (userData?.category === 'هندسية') {
            const engQuery = query(
              collection(db, "EngOffers"),
              where("companyId", "==", id)
            );
            const engQuerySnapshot = await getDocs(engQuery);
            const engOffers = engQuerySnapshot.docs.map((doc) => ({
              ...doc.data(),
              docId: doc.id,
            }));
            
            setOffers(engOffers);
          }
          if (userData?.category === 'عقارات') {
            const engQuery = query(
              collection(db, "RealStateOffers"),
              where("companyId", "==", id)
            );
            const engQuerySnapshot = await getDocs(engQuery);
            const realOffers = engQuerySnapshot.docs.map((doc) => ({
              ...doc.data(),
              docId: doc.id,
            }));
            console.log(realOffers)
            setOffers(realOffers);
          }
  
          if (userData?.category === 'Others') {
            const orderDoc = await getDoc(doc(db, "OtherOrders", id));
            if (orderDoc.exists()) {
              setOffers({ id: orderDoc.id, ...orderDoc.data() });
            } else {
              console.error("No such document!");
            }
          }
        } catch (error) {
          console.error("Error fetching document:", error);
        } finally {
         // setLoading(false);
        }

      }
      fetchOffers()
      fetchUserDataById();
    }
    

  
  }, [isLoading]);

  if (isLoad) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        {" "}
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />{" "}
      </div>
    );
  }

  return (
    
    <div>
    <Container className="mt-4">
    {userData &&
        <div>
        <Row className="text-center">
        <Col>
          <Image 
            src="https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" 
            roundedCircle 
            style={{ width: '150px', height: '150px' }} 
          />
          <h4 className="mt-2">{userData.displayName}</h4>
          <FaUserTie /> مزود خدمة | <FaBusinessTime /> {userData && userData.category} | <FaMapMarkerAlt /> {userData.city}
        </Col>
      </Row>

      {/* صف التنقل وزر تعديل الملف */}
      <Row className="mt-3">
        <Col>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)}>
            <Nav.Item>
              <Nav.Link eventKey="profile">الملف الشخصي</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="portfolio">الاعمال السابقة</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="offers">العروض </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        {user.uid === currentId &&
          <Col md="auto" className='mt-2'>
          <Button variant="primary">تعديل الملف الشخصي</Button>
        </Col>
        }
        
      </Row>

      {/* صف نبذة عني والإحصائيات */}
      <Row className="mt-4">
        <Col md={9}>
          {activeTab === 'profile' && (
            <Card>
              <Card.Body>
                <Card.Title>نبذة عني</Card.Title>
                <Card.Text>
                  {userData.about}
                </Card.Text>
              </Card.Body>
            </Card>
          )}
          {activeTab === 'portfolio' && (
            <Card>
              <Card.Body>
                <Card.Title>الاعمال السابقة</Card.Title>
                <Card.Text>
                  هنا يمكنك عرض الأعمال التي قمت بتنفيذها أو المشاريع التي شاركت فيها.
                </Card.Text>
              </Card.Body>
            </Card>
          )}
          {activeTab === 'offers' && (
            offers?.map((offer) => (
              <div key={offer.id} className="offer-card p-3 border rounded mb-3">
                <Row>
                  <Col>
                    <h3 className="mb-2">
                   
                    </h3>
                    <div className="text-muted mb-2">
                      <div className="mt-3">
                      <h3>{offer.title}</h3>
                      <p>{offer.offerDetails}</p>
                      <p>{offer.offerrDetails}</p>
                      </div>
                      
                    </div>
                  </Col>
                </Row>
  
                <p>{offer.description}</p>
              </div>
            ))
          )}
        </Col>

        <Col md={3}>
          <Card>
            <Card.Body>
              <Card.Title>إحصائيات</Card.Title>
              <ul className="list-unstyled">
                <li>التقييمات : 
                <span className='mx-2'>
                {Array.from({ length: 5 }, (_, index) => {
                  return index < userData.rating ? (
                    <i
                      key={index}
                      className="bi bi-star-fill text-warning"
                    ></i>
                  ) : (
                    <i key={index} className="bi bi-star text-warning"></i>
                  );
                })}
                </span>
                </li>
                <li>معدل إكمال المشاريع: <span>لم يحسب بعد</span></li>
                <li>متوسط سرعة الرد: <span>لم يحسب بعد</span></li>
                <li> حالة الاتصال : <span></span></li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>


        </div>
      
      }
      {/* صف الصورة والمعلومات الأساسية */}
     

    </Container>
  
    </div>
    
  );
};

export default ProfilePage;

import { useEffect, useState } from "react";
import { Col, Row , Form , FormControl} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../../css/CompanySearch.css';
import useUser from "../../hooks/useUser";
import axios from "axios";

const CompanySearch = () => {
    const [price, setPrice] = useState(50);
    const [serviceProvider, setServiceProvider] = useState([]);
    const [serviceProviderChecked, setServiceProviderChecked] = useState([]);
    var serviceProviderCheckedArray = [];
    const [companyPrice, setCompanyPrice] = useState("");
    const [subService, setSubService] = useState("");
    const [serviceList, setServiceList] = useState(""); 
    const { user, isLoading } = useUser();
    const  service_id  = '66479e9946991b3d1efbd22c'; 

    const subServicName = 'تجديد رخصة نشاط تجاري';
    const getServiceList = async () => {
        // Fetch service list from API based on service_id
        const token = await user.getIdToken();
        const headers = token ? { authtoken: token } : {};
        const resp = await axios.get(`/api/order/${service_id}`, { headers });
        console.log(resp.data);
        setServiceList(resp.data.service);
        const subServServiceProvider = resp.data.service.ServiceList.find(
          (service) => service.SubService === subServicName
        );
        setServiceProvider(subServServiceProvider.subListServiceProvider);
        setCompanyPrice(subServServiceProvider.price);
        setSubService(resp.data.service.ServiceList[0].SubService);
        const userData = await axios.get(`/api/users`, { headers });
        
      };

    const handlePriceChange = (event) => {
        setPrice(event.target.value);
    };

    const handleCheckboxChange = (providerId) => {
        // Filter checked providers immediately after checkbox change
        const checkedProviders = serviceProvider.filter(
          (provider) => provider.checked
        );
        setServiceProviderChecked(checkedProviders);
    
        setServiceProvider(
          serviceProvider.map((provider) =>
            provider._id === providerId
              ? { ...provider, checked: !provider.checked }
              : provider
          )
        );
      };

      useEffect(() => {
        if (!isLoading) {
          getServiceList();

        }
      }, [service_id, isLoading]);

      useEffect(() => {
        serviceProviderCheckedArray.push(
          serviceProvider.filter((provider) => provider.checked)
        );
      //  console.log(serviceProviderCheckedArray);
      }, [serviceProviderChecked]);
      
    return ( 
        <div className="company-search-page">
            <div className="container">
                <Row className="filter-options text-center mb-4">
                    <Col lg={3} className="filter-option">
                        <i className="fas fa-dollar-sign m-3"></i> الارخص
                    </Col>
                    <Col lg={3} className="filter-option">
                        <i className="fas fa-tachometer-alt m-3"></i> الاسرع
                    </Col>
                    <Col lg={3} className="filter-option">
                        <i className="fas fa-fire m-3"></i> الاكثر طلب
                    </Col>
                    <Col lg={3} className="filter-option">
                        <i className="fas fa-star m-3"></i> الاكثر تقيماً
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col lg={9}>
                        <h5>الشركات</h5>
                        <div className="companies-list">

                        <Form.Group as={Col} controlId="serviceProvider">
                        <Form.Label>مزود الخدمة</Form.Label>
                       
                        
                        <div
                          style={{
                            maxHeight: "200px",
                            overflowY: "auto",
                            marginTop: "5px",
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                            padding: "5px",
                          }}
                        >
                          {serviceProvider.length === 0 ? (
                            <p>لا يوجد مزود لهذه الخدمة</p>
                          ) : (
                            serviceProvider.map(
                              (provider, index) => (
                                <Form.Check
                                  key={provider._id}
                                  type="checkbox"
                                  label={`${provider.Name} : ${provider.price}`}
                                  id={`provider-${index}`}
                                  checked={provider.checked}
                                  onChange={() => handleCheckboxChange(provider._id)}
                                />
                              )
                            )
                          )}
                        </div>
                      </Form.Group>

                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="filters">
                            <h5>المدن</h5>
                            <div className="cities-filter">
                                <input type="checkbox" id="riyadh" /> <label htmlFor="riyadh">الرياض</label>
                                <br />
                                <input type="checkbox" id="mecca" /> <label htmlFor="mecca">مكة</label>
                                <br />
                                <input type="checkbox" id="hail" /> <label htmlFor="hail">حائل</label>
                                <br />
                                <input type="checkbox" id="qassim" /> <label htmlFor="qassim">القصيم</label>
                                <br />
                                <input type="checkbox" id="medina" /> <label htmlFor="medina">المدينة</label>
                            </div>
                            <hr/>
                            <h5>السعر</h5>
                            <div className="price-filter">
                                <input 
                                    type="range" 
                                    className="form-range" 
                                    id="priceRange" 
                                    min="0" 
                                    max="100" 
                                    value={price}
                                    onChange={handlePriceChange}
                                />
                                <div>السعر: {price} ريال</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
 
export default CompanySearch;

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  ProgressBar,
  Row,
  Col,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardTitle, CardText } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import NotFoundPage from "../NotFoundPage";

let variant = "";
const OrdersMessages = () => {
  const { order_Id } = useParams();
  const [orderDeatails, setOrderDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const { user, isLoading } = useUser();
  const location = useLocation();
  const validStatuses = ["استفسارات", "قيد التنفيذ"];
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const encodedData = queryParams.get("id");
  const companyName = queryParams.get("Name");

  const decodedCompanyId = JSON.parse(atob(encodedData));
  const decodedOrder_id = JSON.parse(atob(order_Id));
  const storedCompanyId = decodedCompanyId;
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);

  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const getOrder = async () => {
    const token = await user.getIdToken();
    const headers = token ? { authtoken: token } : {};
    try {
      const resp = await axios.post(
        `/api/dashboard/order/${decodedOrder_id}`,
        null,
        { headers }
      );
      const decodedToken = jwtDecode(resp.data);
      setOrderDetails(decodedToken);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };
  //useEffect
  useEffect(() => {
    if (!isLoading && user) {
     isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getOrder();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
  }, [order_Id, isLoading]);


  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }

  const ConversationBox = ({ senderName, message, img, profilePic }) => {
    return (
      <div className="conversation-box mt-3">
        <div className="sender-name">
          <img
          className="profile-pic"
            src={`https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg`}
            alt="profile"
          />
          <strong>{senderName}</strong>{" "}
        </div>
        <div className="message">{message}</div>
        <div>
          {img && (
            <a
              href={`https://magalaelbinaa.dafaalla.com/${img}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ cursor: "pointer" }}
                src={`https://magalaelbinaa.dafaalla.com/${img}`}
                alt="img"
                width={400}
                height={400}
              />{" "}
            </a>
          )}
        </div>
        <hr />
      </div>
    );
  };

  if (!isLoading) {
    if (!user) return navigate("/customerlogin");
  }


  return (
    <Container className="mt-4" style={{ widt: "70%" }}>
      {isLoading ? (
        // Show spinner while loading
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <Card>
            <div className="text-start">
              <Button
                variant="info"
                style={{ width: "100px" }}
                className="mt-2 mx-2 typing"
                onClick={() => window.print()}
              >
                <FontAwesomeIcon icon={faPrint} size="lg" />
                طباعة
              </Button>
            </div>
            <CardBody className="print-section">
              <CardTitle>{orderDeatails.userName}</CardTitle>
              <Row>
                <Col sm={6}>
                  <CardText className="mt-3">
                    <strong>الطلب : </strong> {orderDeatails.subService}
                  </CardText>
                  <CardText className="mt-3">
                    <strong>الايميل :</strong> {orderDeatails.email}
                  </CardText>
                  <CardText className="mt-3">
                    <strong>رقم الهاتف :</strong> {orderDeatails.phoneNumber}
                  </CardText>
                  <CardText className="mt-3">
                    <strong>حالة الطلب :</strong> {orderDeatails.status}
                  </CardText>
                  <CardText className="mt-3">
                    <strong>سعر مزود الخدمة :</strong>{" "}
                    {orderDeatails.CompanyPrice}
                  </CardText>
                </Col>
                <Col sm={6}>
                  <CardText className="mt-3">
                    <strong>المدينة :</strong> {orderDeatails.city}
                  </CardText>
                  <CardText>
                    <strong>التاريخ:</strong> {orderDeatails.date}
                  </CardText>
                  <CardText>
                    <strong>الوقت:</strong> {orderDeatails.time}
                  </CardText>
                  <CardText>
                    <strong> طريقة الدفع : </strong>{" "}
                    {orderDeatails.paymentMethod}
                  </CardText>
                  <CardText>
                    <strong> سعرك : </strong>{" "}
                    {orderDeatails.cutomerPrice
                      ? orderDeatails.cutomerPrice
                      : "لم تحدد  سعر و اعتمدن سعر مزود الخدمة"}
                  </CardText>
                  {orderDeatails.status === "جديدة" && (
                    <div className="alert-info" role="alert">
                      <CardText>
                        <strong> في انتظار موافقة أحد مزودي الخدمات</strong>
                      </CardText>
                    </div>
                  )}
                  {orderDeatails.status === "استفسارات" && (
                    <div>
                      <div className="alert-danger" role="alert">
                        <CardText>
                          {!orderDeatails.cusConfirmation && (
                            <strong>
                              {" "}
                              هذة مساحة للنقاش حول الخدمة قبل البدء يمكنك مناقشة
                              مزود الخدمة و بعد التأكد طلبك اضغط على تأكيد الطلب
                            </strong>
                          )}
                        </CardText>
                      </div>
                      <div className="alert-success" role="alert">
                        <CardText>
                          {orderDeatails.cusConfirmation && (
                            <strong>
                              {" "}
                              لقد تم تأكيد طلبك سيبدأ مزود الخدمة في التنفيذ
                            </strong>
                          )}
                        </CardText>
                      </div>
                    </div>
                  )}

                  {orderDeatails.status === "قيد التنفيذ" && (
                    <div>
                      <strong> مستوى التقدم: </strong>
                      <ProgressBar
                        fade="true"
                        now={orderDeatails.progress}
                        label={`${orderDeatails.progress}%`}
                        style={{ height: "30px" }}
                        variant={variant}
                      />
                    </div>
                  )}
                </Col>
                {orderDeatails.status === "قيد التنفيذ" &&
                  orderDeatails.CompanyComplete && (
                    <div className="alert alert-success mt-2 text-center">
                      {" "}
                      حدث مزود الخدمة البيانات بإكمال انجاز الخدمة قم بالتأكيد
                      على ذلك
                    </div>
                  )}
                {orderDeatails.status === "مكتملة" && (
                  <div>
                    <Button
                      disabled
                      variant="success"
                      type="submit"
                      className="w-100 mt-4"
                    >
                      تم انجاز الخدمة
                    </Button>
                  </div>
                )}
              </Row>
            </CardBody>
          </Card>
          <Row className="mt-4">
            {validStatuses.includes(orderDeatails.status) && (
              <Card style={{ boxShadow: "none" }}>
                <CardBody>
                  <CardTitle className="mb-5">الرسائل</CardTitle>
                  {orderDeatails.messages &&
                    orderDeatails.messages
                      .filter(
                        (message) =>
                          message.resciverId === storedCompanyId ||
                          message.senderId === storedCompanyId
                      )
                      .map((message , i) => (
                        <ConversationBox
                        key={i}
                        senderName={message.Name}
                        message={message.message}
                        img={message.fileUrl}
                       
                      />
                      ))}

                  <div></div>
                </CardBody>
              </Card>
            )}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default OrdersMessages;

import React, { useEffect, useState } from "react";
import { Form, Button, Container, Toast } from "react-bootstrap";
import { db, collection, addDoc, serverTimestamp, doc, getDoc } from "../../../firebase";
import useUser from "../../../hooks/useUser";

const AddEngOffer = () => {
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("الرياض");
  const [offerrDetails, setOfferrDetails] = useState("");
  const [category, setCategory] = useState("خدمات الرخص التجارية");
  const { user, isLoading } = useUser();
  const [companyName, setCompanyName] = useState("");
  const [userData, setUserData] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [rating, setRating] = useState("");
  const [uploading, setUploading] = useState(false);
  const [showToast, setShowToast] = useState(false); // التحكم في ظهور الإشعار

  useEffect(() => {
    setShowToast(true);
    if (!isLoading) {
      setCompanyName(user.displayName);
      setCompanyId(user.uid);

      async function fetchUserDataById(userId) {
        try {
          const docRef = doc(db, "Users", userId);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists()) {
            const userData = {
              id: docSnapshot.id,
              ...docSnapshot.data(),
            };
            setUserData(userData);
            setRating(userData.rating)
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching document: ", error);
        }
      }
      fetchUserDataById(user.uid);
     
    }
  }, [isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    console.log(rating)
    try {
      await addDoc(collection(db, "EngOffers"), {
        companyId,
        companyName,
        title,
        city,
        offerrDetails,
        category,
        rating,
        responses: [],
        views: 0,
        orderCount: 0,
        status: "active",
        createdAt: serverTimestamp(),
      });

      // بعد إضافة العرض بنجاح، إظهار الإشعار وتفريغ الخانات
      setShowToast(true); 
      setTitle("");
      setCity("الرياض");
      setOfferrDetails("");
      setCategory("خدمات الرخص التجارية");
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">اضافة عرض جديد</h1>

      {/* Toast للإشعار */}
      <Toast
        show={true}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide={false}
        className="position-fixed bottom-0 end-0 m-3"
        style={{ zIndex: 9999 }}
      >
        <Toast.Header>
          <strong className="me-auto text-success">تم بنجاح!</strong>
        </Toast.Header>
        <Toast.Body>تمت إضافة العرض بنجاح.</Toast.Body>
      </Toast>

      <Form onSubmit={handleSubmit} className="mx-auto p-4 border rounded shadow-sm bg-light" style={{ maxWidth: '600px' }}>
        <Form.Group controlId="companyName" className="mb-3">
          <Form.Label>اسم الشركة</Form.Label>
          <Form.Control
            readOnly
            type="text"
            placeholder="أدخل اسمك"
            value={companyName}
            required
          />
        </Form.Group>

        <Form.Group controlId="orderTitle" className="mb-3">
          <Form.Label>عنوان العرض</Form.Label>
          <Form.Control
            type="text"
            placeholder="أدخل عنوان العرض"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="category" className="mb-3">
          <Form.Label>الفئة</Form.Label>
          <Form.Control
            as="select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="خدمات الرخص التجارية">خدمات الرخص التجارية</option>
            <option value="خدمات البلدية">خدمات البلدية</option>
            <option value="استشارات هندسية">استشارات هندسية</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="city" className="mb-3">
          <Form.Label>المدينة</Form.Label>
          <Form.Control
            as="select"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          >
            <option value="الرياض">الرياض</option>
            <option value="جدة">جدة</option>
            <option value="مكة">مكة المكرمة</option>
            <option value="المدينة المنورة">المدينة المنورة</option>
            <option value="الدمام">الدمام</option>
            <option value="الخبر">الخبر</option>
            <option value="الطائف">الطائف</option>
            <option value="القصيم">القصيم</option>
            <option value="تبوك">تبوك</option>
            <option value="أبها">أبها</option>
            <option value="جازان">جازان</option>
            <option value="نجران">نجران</option>
            <option value="حائل">حائل</option>
            <option value="الباحة">الباحة</option>
            <option value="عرعر">عرعر</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="offerrDetails" className="mb-3">
          <Form.Label>تفاصيل العرض</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="أدخل تفاصيل العرض"
            value={offerrDetails}
            onChange={(e) => setOfferrDetails(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100" disabled={uploading}>
          {uploading ? "جارٍ رفع العرض..." : "اضافة العرض"}
        </Button>
      </Form>
    </Container>
  );
};

export default AddEngOffer;

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Nav,
  Card,
  Badge,
} from "react-bootstrap";
import { FaUserTie, FaMapMarkerAlt } from "react-icons/fa";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { MDBSpinner } from "mdb-react-ui-kit";
import useUser from "../../hooks/useUser";
import moment from "moment";
import { Link } from "react-router-dom";

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCHf1sAy7wB4CIGBw-lsaI4DaCXOXuLwNA",
  authDomain: "my-react-blog-191fa.firebaseapp.com",
  projectId: "my-react-blog-191fa",
  storageBucket: "my-react-blog-191fa.appspot.com",
  messagingSenderId: "821981323548",
  appId: "1:821981323548:web:f85ed9cf7518f6c4ba9ad9",
  measurementId: "G-JZZWK4GFWJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const CustomerProfile = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const [userData, setUserData] = useState();
  const [currentId, setCurrentId] = useState();
  const [isLoad, setIsLoad] = useState(false);
  const [engOrders, setEngOrders] = useState([]);
  const [realStateOrders, setRealStateOrders] = useState([]);
  const [otherOrders, setOtherOrders] = useState([]);
  const [unreadEngCount, setUnreadEngCount] = useState(0);
  const [unreadRealCount, setUnreadRealCount] = useState(0);
  const [unreadOtherCount, setUnreadOtherCount] = useState(0);

  const { user, isLoading } = useUser();

  useEffect(() => {
    if (!isLoading) {
      setIsLoad(true);
      const fetchUserDataById = async () => {
        try {
          if (user) {
            const userDoc = await getDoc(doc(db, "Users", user.uid));
            if (userDoc.exists()) {
              setCurrentId(userDoc.id);
              setUserData(userDoc.data());
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setIsLoad(false);
        }
      };

      const fetchOrders = async () => {
        try {
          // Fetch Engineering Orders
          const engQuery = query(
            collection(db, "EngOrders"),
            where("customerId", "==", user.uid)
          );
          const engQuerySnapshot = await getDocs(engQuery);
          const engOrders = engQuerySnapshot.docs.map((doc) => ({
            ...doc.data(),
            docId: doc.id,
          }));
          setEngOrders(engOrders);

          // Count unread responses
          const unreadEng = engOrders.reduce((acc, order) => {
            return (
              acc + order.responses.filter((resp) => !resp.isReaded).length
            );
          }, 0);
          setUnreadEngCount(unreadEng);

          // Fetch Real Estate Orders
          const realQuery = query(
            collection(db, "RealStateOrders"),
            where("customerId", "==", user.uid)
          );
          const realQuerySnapshot = await getDocs(realQuery);
          const realOrders = realQuerySnapshot.docs.map((doc) => ({
            ...doc.data(),
            docId: doc.id,
          }));
          setRealStateOrders(realOrders);

          // Count unread responses
          const unreadReal = realOrders.reduce((acc, order) => {
            return (
              acc + order.responses.filter((resp) => !resp.isReaded).length
            );
          }, 0);
          setUnreadRealCount(unreadReal);

          // Fetch Other Orders
          const otherQuery = query(
            collection(db, "OtherOrders"),
            where("customerId", "==", user.uid)
          );
          const otherQuerySnapshot = await getDocs(otherQuery);
          const otherOrders = otherQuerySnapshot.docs.map((doc) => ({
            ...doc.data(),
            docId: doc.id,
          }));
          setOtherOrders(otherOrders);

          // Count unread responses
          const unreadOther = otherOrders.reduce((acc, order) => {
            return (
              acc + order.responses.filter((resp) => !resp.isReaded).length
            );
          }, 0);
          setUnreadOtherCount(unreadOther);
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      };

      fetchUserDataById();
      fetchOrders();
    }
  }, [isLoading, user]);

  const handleNavigateToOrderDetails = (docId) => {
    // Logic to navigate to order details page
    console.log("Navigating to order details for:", docId);
  };

  const formatTimeAgo = (timestamp) => {
    const date = timestamp instanceof Date ? timestamp : timestamp?.toDate();
    moment.locale("ar");
    return moment(date).fromNow();
  };

  const sortOrders = (orders) => {
    return orders.sort((a, b) => {
      const unreadA = a.responses.filter((resp) => !resp.isReaded).length;
      const unreadB = b.responses.filter((resp) => !resp.isReaded).length;
      return unreadB - unreadA; // Sort by unread responses in descending order
    });
  };

  if (isLoad) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />
      </div>
    );
  }

  return (
    <Container className="mt-4">
      {userData && (
        <div>
          <Row className="text-center">
            <Col>
              <Image
                src="https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                roundedCircle
                style={{ width: "150px", height: "150px" }}
              />
              <h4 className="mt-2">{userData.displayName}</h4>
              <FaUserTie /> عميل | <FaMapMarkerAlt /> {userData.city}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Nav
                variant="tabs"
                activeKey={activeTab}
                onSelect={(selectedKey) => setActiveTab(selectedKey)}
              >
                <Nav.Item>
                  <Nav.Link eventKey="profile">الملف الشخصي</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Eng">
                    الطلبات الهندسية
                    {unreadEngCount > 0 && (
                      <Badge pill bg="danger" className="ms-2  mx-3">
                        {unreadEngCount}
                      </Badge>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Real">
                    طلبات العقارات
                    {unreadRealCount > 0 && (
                      <Badge pill bg="danger" className="ms-2 mx-3">
                        {unreadRealCount}
                      </Badge>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="others">
                    الطلبات الأخرى
                    {unreadOtherCount > 0 && (
                      <Badge pill bg="danger" className="ms-2  mx-3">
                        {unreadOtherCount}
                      </Badge>
                    )}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            {user.uid === currentId && (
              <Col md="auto" className="mt-2">
                <Button variant="primary">تعديل الملف الشخصي</Button>
              </Col>
            )}
          </Row>

          <Row className="mt-4">
            <Col md={1}></Col>
            <Col md={10}>
              {activeTab === "profile" && (
                <Card>
                  <Card.Body>
                    <Card.Title>نبذة عني</Card.Title>
                    <Card.Text>{userData.about}</Card.Text>
                  </Card.Body>
                </Card>
              )}

              {activeTab === "Eng" &&
                sortOrders(engOrders).map((order) => (
                <Link to={`/orderdetails/Engineering/${order.docId}`}>
                <div
                key={order.docId}
                className="request-card p-3 border rounded mb-3"
              >
              <Row>
              <Col>
                <h3 className="mb-2">{order.orderTitle}</h3>
              </Col>
              <Col>
                <Col className="text-start">
                  {order.responses.filter((resp) => !resp.isReaded)
                    .length > 0 && (
                    <Badge pill bg="danger" className="me-2">
                      {
                        order.responses.filter((resp) => !resp.isReaded)
                          .length
                      }
                    </Badge>
                  )}
                </Col>
              </Col>
            </Row>
                <div className="d-flex align-items-center mb-2">
                  <p className="text-muted">
                    <i className="bi bi-clock mx-2"></i> منذ{" "}
                    {formatTimeAgo(order.createdAt)} |{" "}
                    <i className="bi bi-reply mx-2"></i> عدد الردود:{" "}
                    {order.responses.length} |{" "}
                    <i className="bi bi-eye mx-2"></i> عدد المشاهدات:{" "}
                    {order.views || 0}
                  </p>
                </div>
                <p>{order.orderDetails}</p>
              </div>
                </Link>
                 
                ))}

              {activeTab === "Real" &&
                sortOrders(realStateOrders).map((order) => (
                    <Link to={`/orderdetails/RealState/${order.docId}`}>
                    <div
                    key={order.docId}
                    className="request-card p-3 border rounded mb-3"
                  >
                    <Row>
                      <Col>
                        <h3 className="mb-2">{order.title}</h3>
                      </Col>
                      <Col>
                        <Col className="text-start">
                          {order.responses.filter((resp) => !resp.isReaded)
                            .length > 0 && (
                            <Badge pill bg="danger" className="me-2">
                              {
                                order.responses.filter((resp) => !resp.isReaded)
                                  .length
                              }
                            </Badge>
                          )}
                        </Col>
                      </Col>
                    </Row>
                    <div className=" mb-2">
                      <p className="text-muted">
                        <i className="bi bi-clock mx-2"></i> منذ{" "}
                        {formatTimeAgo(order.createdAt)} |{" "}
                        <i className="bi bi-reply mx-2"></i> عدد الردود:{" "}
                        {order.responses.length} |{" "}
                        <i className="bi bi-eye mx-2"></i> عدد المشاهدات:{" "}
                        {order.views || 0}
                      </p>
                    </div>
                    <p>{order.orderDetails}</p>
                  </div>
                    </Link>
                  
                ))}

              {activeTab === "others" &&
                sortOrders(otherOrders).map((order) => (
                  <div
                    key={order.docId}
                    className="request-card p-3 border rounded mb-3"
                  >
                  <Row>
                  <Col>
                    <h3 className="mb-2">{order.title}</h3>
                  </Col>
                  <Col>
                    <Col className="text-start">
                      {order.responses.filter((resp) => !resp.isReaded)
                        .length > 0 && (
                        <Badge pill bg="danger" className="me-2">
                          {
                            order.responses.filter((resp) => !resp.isReaded)
                              .length
                          }
                        </Badge>
                      )}
                    </Col>
                  </Col>
                </Row>

                    <div className="d-flex align-items-start mb-2">
                      {order.responses.filter((resp) => !resp.isReaded).length >
                        0 && (
                        <Badge pill bg="danger" className="me-2">
                          {
                            order.responses.filter((resp) => !resp.isReaded)
                              .length
                          }
                        </Badge>
                      )}
                      <p className="text-muted">
                        <i className="bi bi-clock mx-2"></i> منذ{" "}
                        {formatTimeAgo(order.createdAt)} |{" "}
                        <i className="bi bi-reply mx-2"></i> عدد الردود:{" "}
                        {order.responses.length} |{" "}
                        <i className="bi bi-eye mx-2"></i> عدد المشاهدات:{" "}
                        {order.views || 0}
                      </p>
                    </div>
                    <p>{order.orderDetails}</p>
                  </div>
                ))}
            </Col>
            <Col md={1}></Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default CustomerProfile;

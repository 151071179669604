import React, { useEffect, useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { db, collection, addDoc, serverTimestamp, getDoc, doc } from "../../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import useUser from "../../../hooks/useUser";

const AddRealStateOffer = () => {
  const [userData, setUserData] = useState();
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("الرياض");
  const [offerDetails, setOfferDetails] = useState("");
  const [price, setPrice] = useState("");
  const [publisher, setPublisher] = useState("");
  const [area, setArea] = useState(""); // مساحة العقار
  const [streetWidth, setStreetWidth] = useState(""); // عرض الشارع
  const [type, setType] = useState("شقة"); // نوع العقار
  const [rooms, setRooms] = useState(""); // عدد الغرف
  const [file, setFile] = useState(null);
  const { user, isLoading } = useUser();
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setCompanyName(user.displayName);
      setCompanyId(user.uid);

      const fetchUserDataById = async () => {
        try {
          if (!isLoading && user) {
            const userDoc = await getDoc(doc(db, "Users", user.uid));
            if (userDoc.exists()) {
              setUserData(userDoc.data());
            } else {
              console.error("No such document!");
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserDataById();
    }
  }, [isLoading]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      let fileURL = "";

      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `orders/RealState${file.name}`);
        await uploadBytes(storageRef, file);
        fileURL = await getDownloadURL(storageRef);
      }

      await addDoc(collection(db, "RealStateOffers"), {
        publisher: user.displayName,
        companyId: user.uid,
        title,
        city,
        price,
        area,
        streetWidth,
        rooms,
        type,
        offerDetails,
        fileURL, // رابط الملف المرفوع
        responses: [],
        views: 0,
        createdAt: serverTimestamp(),
      });

      alert("تم إنشاء العرض بنجاح!");
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">إنشاء عرض عقار جديد</h1>
      <Form onSubmit={handleSubmit} className="mx-auto p-4 border rounded shadow-sm bg-light" style={{ maxWidth: "600px" }}>
        <Form.Group controlId="companyName" className="mb-3">
          <Form.Label>اسم العميل</Form.Label>
          <Form.Control readOnly type="text" placeholder="أدخل اسمك" value={companyName} required />
        </Form.Group>

        <Form.Group controlId="title" className="mb-3">
          <Form.Label>عنوان العرض</Form.Label>
          <Form.Control type="text" placeholder="شقة، فيلا، أرض، مزرعة..." value={title} onChange={(e) => setTitle(e.target.value)} required />
        </Form.Group>

        <Form.Group controlId="city" className="mb-3">
          <Form.Label>المدينة</Form.Label>
          <Form.Control as="select" value={city} onChange={(e) => setCity(e.target.value)} required>
            <option value="الرياض">الرياض</option>
            <option value="جدة">جدة</option>
            <option value="مكة">مكة المكرمة</option>
            <option value="المدينة المنورة">المدينة المنورة</option>
            <option value="الدمام">الدمام</option>
            <option value="الخبر">الخبر</option>
            <option value="الطائف">الطائف</option>
            <option value="القصيم">القصيم</option>
            <option value="تبوك">تبوك</option>
            <option value="أبها">أبها</option>
            <option value="جازان">جازان</option>
            <option value="نجران">نجران</option>
            <option value="حائل">حائل</option>
            <option value="الباحة">الباحة</option>
            <option value="عرعر">عرعر</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="type" className="mb-3">
          <Form.Label>نوع العقار</Form.Label>
          <Form.Control as="select" value={type} onChange={(e) => setType(e.target.value)} required>
            <option value="شقة">شقة</option>
            <option value="فيلا">فيلا</option>
            <option value="أرض">أرض</option>
            <option value="مزرعة">مزرعة</option>
            <option value="استراحة">استراحة</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="price" className="mb-3">
          <Form.Label>السعر</Form.Label>
          <Form.Control type="text" placeholder="أدخل السعر" value={price} onChange={(e) => setPrice(e.target.value)} required />
        </Form.Group>

        <Form.Group controlId="area" className="mb-3">
          <Form.Label>المساحة (م²)</Form.Label>
          <Form.Control type="text" placeholder="أدخل مساحة العقار" value={area} onChange={(e) => setArea(e.target.value)} required />
        </Form.Group>

        <Form.Group controlId="streetWidth" className="mb-3">
          <Form.Label>عرض الشارع (متر)</Form.Label>
          <Form.Control type="text" placeholder="أدخل عرض الشارع" value={streetWidth} onChange={(e) => setStreetWidth(e.target.value)} required />
        </Form.Group>


        <Form.Group controlId="rooms" className="mb-3">
          <Form.Label>عدد الغرف</Form.Label>
          <Form.Control type="text" placeholder="أدخل عدد الغرف" value={rooms} onChange={(e) => setRooms(e.target.value)} required />
        </Form.Group>

        <Form.Group controlId="offerDetails" className="mb-3">
          <Form.Label>تفاصيل العرض</Form.Label>
          <Form.Control as="textarea" rows={5} placeholder="أدخل تفاصيل العرض" value={offerDetails} onChange={(e) => setOfferDetails(e.target.value)} required />
        </Form.Group>

        <Form.Group controlId="file" className="mb-3">
          <Form.Label>إرفاق صورة</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100" disabled={uploading}>
          {uploading ? "جارٍ رفع العرض..." : "إنشاء العرض"}
        </Button>
      </Form>
    </Container>
  );
};

export default AddRealStateOffer;

import React from 'react';
import './App.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import NavBar from './component/NavBar';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import RoutesLayout from './Routes/RoutesLayout';
import AboutPage from './pages/AboutPage';
import NotFoundPage from './pages/NotFoundPage';
import CreateAccountPage from './pages/CreateAccountPage';
import CustomerLogin from './pages/Login';

import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import './index.css'
import 'animate.css';

import OrderPage from './pages/OrderPage';
import AdminDashBoard from './pages/admin/AdminDashBoard';
import OrederList from './pages/OrderList';
import ServicesPage from './pages/ServicesPage';
import CompanyDashboard from './pages/company/CompanyDashboard';
import CompanyOrderList from './pages/company/CompanyOrderList';
import CompanyCompleteOrders from './pages/company/CompanyCompleteOrders';
import CompanySignUp from './pages/company/CompanySignUp';
import CompanyOrderDetails from './pages/company/CompanyOrderDetails';
import CompanyMessageBox from './pages/company/CompanyMessegeBox';
import CompanyInfoOrders from './pages/company/CompanyInfoOrders';

import InProgressOrder from './pages/company/InProgressOrder';
import NewOrders from './pages/company/NewOrders';
import CompanyServices from './pages/company/CompanyServices';
import Messages from './pages/customer/Messages';
import CustomerOrderList from './pages/customer/CutomerOrderList';
import CustomerDashboard from './pages/customer/CustomerDashboad';

import CustomerCompleteOrders from './pages/customer/CustomerCompleteOrders';
import CustomerAllOrdersList from './pages/customer/CustomerAllOrdersList';
import CustomerInProgressOrders from './pages/customer/CustomerInProgressOrders';
import CustomerOrderDetails from './pages/customer/CustomerOrderDetails';
import CustomertWaitingOrder from './pages/customer/CustomerWaitOrder';
import CustomerSignUp from './pages/customer/CustomerSignUp';
import ServiceListPage from './pages/ServiceList';
import SubServiceList from './pages/customer/SubServiceList';
import CompleteTheOrder from './pages/customer/CompleteTheOrder';
import CustomerInfoOrders from './pages/customer/CustomerInfoOrders';
import AdminAllOrders from './pages/admin/AdminAllOrders';
import OrdersInfo from './pages/admin/OrderInfo';
import OrdersMessages from './pages/admin/OrderMessages';
import AllNewOrders from './pages/admin/AllNewOrders';
import AllCompleteOrders from './pages/admin/AllCompleteOrderes';
import AllProgressOrderes from './pages/admin/AllProgressOrders';
import AllInfoOrders from './pages/admin/AllInfoOrders';
import Reports from './pages/admin/Reports';
import OrdersFromReport from './pages/admin/OrdersFromReport';
import UsersList from './pages/admin/UsersList';
import ContactUs from './components/ContactUs';
import Users from './pages/admin/Users';
import UserReport from './pages/admin/UserReport';
import UserOrders from './pages/admin/UserOrders';
import WaitingApprove from './pages/WaitingApproval';
import NewUsers from './pages/admin/NewUsers';
import UserProfile from './pages/UserProfile';
import Login from './pages/Login';
import Services from './pages/admin/Services';
import EmployeeDashboard from './pages/employee/EmployeeDashboard';
import EmployeeTasksList from './pages/employee/EmployeeTasksList';
import EmployeeTaskDetails from './pages/employee/EmployeeTaskDetails';
import AdminTasksList from './pages/admin/AdminTasksList';
import AdminTaskDetails from './pages/admin/AdminTaskDetails';
import CompanySearch from './pages/customer/CompanySearch';
import CompanyProfile from './pages/company/CompanyProfile';
import EngOrder from './pages/customer/EngOrder';
import AddEngOffer from './pages/company/Eng/AddEngOffer';
import OrderDetails from './pages/orderDetails';
//import MessagePage from './pages/MessagePage';
import Messenger from './pages/Massenger';
import OtherOffer from './pages/company/others/AddOtherOffers';
import AddOtherOffer from './pages/company/others/AddOtherOffers';
import AddOtherOrder from './pages/customer/AddOtherOrder';
import AddRealStateOrder from './pages/customer/AddRealEstateOrder';
import AddRealStateOffer from './pages/company/RealEstate/AddRealStateOffer';
import EngineeringMembers from './pages/company/Eng/EngineeringMembers';
import CustomerProfile from './pages/customer/CustomerProfile';




function App() {
  return (
    <BrowserRouter>
    <div>
    <NavBar/>
  
       <div id ="page-body">
       <Routes>
        {/* ---------- Admin --------------*/}
       <Route path='/about' element={<AboutPage/>} />
       <Route path='/create-account' element={<CreateAccountPage/>} />
       <Route path='/order/:service_id' element={<OrderPage />} />
       <Route path='/dashboard' element={<AdminDashBoard />} />
       <Route path='/dashboard/allorders' element={<AdminAllOrders />} />
       <Route path='/dashboard/newusers' element={<NewUsers />} />
       <Route path='/dashboard/neworders' element={<AllNewOrders />} />
       <Route path='/dashboard/completeorders' element={<AllCompleteOrders />} />
       <Route path='/dashboard/inprogressorders' element={<AllProgressOrderes />} />
       <Route path='/dashboard/infoorders' element={<AllInfoOrders />} />
       <Route path='/dashboard/order/:order_Id' element={<OrdersInfo />} />
       <Route path='/dashboard/order/:order_Id/messages' element={<OrdersMessages />} />
       <Route path='/dashboard/reports' element={<Reports />} />
       <Route path='/dashboard/userslist' element={<UsersList />} />
       <Route path='/dashboard/userslist/:type' element={<Users />} />
       <Route path='/dashboard/userslist/:type/:userId' element={<UserReport />} />
       <Route path='/dashboard/userslist/:type/:userId/userorders/:orderstype' element={<UserOrders />} />
       <Route path='/dashboard/reports/:type' element={<OrdersFromReport />} />
       <Route path='/dashboard/taskslist' element={<AdminTasksList />} />
       <Route path='/dashboard/taskslist/:task_Id' element={<AdminTaskDetails />} />
       <Route path='/admindashboard/orderlist' element={<OrederList />} />
       <Route path='/admindashboard/services' element={<ServicesPage />} />
       <Route path='/dashboard/services' element={<Services />} />
       <Route path='/services/subservices/:serviceId' element={<SubServiceList />} />
       {/* ---------- Admin --------------*/}
       <Route path='/employeedashboard' element={<EmployeeDashboard />} />
       <Route path='/employeedashboard/taskslist' element={<EmployeeTasksList />} />
       <Route path='/employeedashboard/taskslist/:task_Id' element={<EmployeeTaskDetails />} />
        {/* ---------- Company Edit--------------*/}
        <Route path='/companyprofile/:id' element={<CompanyProfile />} />
        <Route path='/addengoffer' element={<AddEngOffer/>} />
        <Route path='/addrealstateoffer' element={<AddRealStateOffer/>} />
        <Route path='/addotheroffer' element={<AddOtherOffer/>} />
        <Route path='/engineeringmemb' element={<EngineeringMembers/>} />

        {/* ---------- Company --------------*/}
       <Route path='/companyregister' element={<CompanySignUp />} />
       <Route path='/companydashboard' element={<CompanyDashboard />} />
       <Route path='/companyuser/orderlist' element={<CompanyOrderList />} />
       <Route path='/companydashboard/orderlist/orderdetails/:order_Id' element={<CompanyOrderDetails />} />
       <Route path='/companydashboard/completeorders' element={<CompanyCompleteOrders />} />
       <Route path='/companydashboard/infoorders' element={<CompanyInfoOrders />} />
       <Route path='/companydashboard/inprogressorders' element={<InProgressOrder />} />
       <Route path='/companydashboard/services' element={<CompanyServices />} />
       <Route path='/companydashboard/messagebox' element={<CompanyMessageBox />} />
       <Route path='/companydashboard/neworders' element={<NewOrders />} />
       {/* ---------- Customer --------------*/}
       <Route path='/profile' element={<CustomerProfile/>} />
       <Route path='/orderdetails/:category/:id' element={<OrderDetails/>} />
       <Route path='/messages' element={<Messenger/>} />
       <Route path='/engorder' element={<EngOrder/>} />
       <Route path='/addrealstateorder' element={<AddRealStateOrder/>} />
       <Route path='/addotherorder' element={<AddOtherOrder/>} />


       <Route path='/customersregister' element={<CustomerSignUp/>} />
       <Route path='/customerdashboard' element={<CustomerDashboard />} />
       <Route path='/profile' element={<UserProfile />} />
       <Route path='/customerdashboard/allorderlist' element={<CustomerAllOrdersList />} />
       <Route path='/customerdashboard/orderlist' element={<CustomerOrderList />} />
       <Route path='/customerdashboard/completeorders' element={<CustomerCompleteOrders />} />
       <Route path='/customerdashboard/infoorders' element={<CustomerInfoOrders />} />
       <Route path='/customerdashboard/inprogressorders' element={<CustomerInProgressOrders />} />
       <Route path='/customerdashboard/:userId/waitingorder' element={<CustomertWaitingOrder />} />
       <Route path='/customerdashboard/companysearch' element={<CompanySearch />} />
       <Route path='/customerdashboard/:serviceId/completeorder' element={<CompleteTheOrder />} />
       <Route path='/customerdashboard/orderlist/orderdetails/:order_Id' element={<CustomerOrderDetails />} />
       <Route path='/customerdashboard/orderlist/:order_Id' element={<Messages />} />
       <Route path='/servicelist' element={<ServiceListPage />} />
      
       <Route path='/' element={<Home/>} />
       <Route path='/contact' element={<ContactUs/>} />
       <Route path='/waitingapprove' element={<WaitingApprove/>} />
       <Route path='/login' element={<Login/>} />
       <Route path='*' element={<NotFoundPage/>} />
      </Routes>
       </div>
    </div>
    </BrowserRouter>
    
    
  );
}

export default App;

// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, serverTimestamp , getDoc , doc } from "firebase/firestore"; 

const firebaseConfig = {
    apiKey: "AIzaSyCHf1sAy7wB4CIGBw-lsaI4DaCXOXuLwNA",
    authDomain: "my-react-blog-191fa.firebaseapp.com",
    projectId: "my-react-blog-191fa",
    storageBucket: "my-react-blog-191fa.appspot.com",
    messagingSenderId: "821981323548",
    appId: "1:821981323548:web:f85ed9cf7518f6c4ba9ad9",
    measurementId: "G-JZZWK4GFWJ"
  };
  

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc, serverTimestamp , getDoc , doc };

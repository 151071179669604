import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  ProgressBar,
  ListGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faClipboardCheck,
  faClock,
  faEnvelope,
  faFileAlt,
  faListAlt,
  faServer,
  faUserClock,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import useUser from "../../hooks/useUser";
import WaitingApprove from "../WaitingApproval";

const CustomerDashboard = () => {
  const [orders, setOrders] = useState([]);
  const [userProfile, setUserProfile] = useState({ Email: "", CustName: "" });
  const [serviceList, setServiceList] = useState([]);
  const [_2orders, set_2orders] = useState({ city: "" });
  const [messNum, setMessNum] = useState(0);
  const [notReadOrNum, setNotReadOrNum] = useState(0);
  const [error, setError] = useState("");
  const [ordersLoading, setOrdersLoading] = useState(true);
  const { user, isLoading } = useUser();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isConfermed, setIsConfermed] = useState(true);
  const [mess, setMess] = useState([]);

  const getData = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.get(`/api/customerdashboard/userdata`, {
        headers,
      });
      setUserProfile(resp.data.user);
      setIsConfermed(resp.data.user.isConfermed);

      console.log(_2orders.length < 0);

      const [obj1] = resp.data.orders;
      const _2order = [obj1];
      set_2orders(_2order);

      if (resp.data.orders.length === 0)
        set_2orders([{ city: "", time: "", message: [] }]);
      setOrders(resp.data.orders);
      setOrdersLoading(false);
      const numUnreadMessages = resp.data.orders.flatMap((order) =>
        order.messages.filter(
          (message) => message.isReaded === false && !message.resciverId
        )
      ).length;
      const numNewConfirm = resp.data.orders.filter(
        (order) => order.isConfirm && !order.isUserReadConfirm
      ).length;

      setMessNum(numUnreadMessages);
      setNotReadOrNum(numNewConfirm);
      setMess(
        resp.data.orders.flatMap((order) =>
          order.messages.filter(
            (message) => message.isReaded === false && !message.resciverId
          )
        )
      );
      console.log(mess);
    } catch (err) {
      setError("االمستخدم غير موجود");
      setOrders([]);
      setMessNum(0);
    }
  };

  useEffect(() => {
    if (!isLoading && user) {
      getData();
    }
  }, [navigate, isLoading]);

  if (!isLoading) {
    if (!user) return navigate("/customerlogin");
  }

  if (!isConfermed) {
    return <WaitingApprove />;
  }

  return (
    <div className="container">
      {isLoading || ordersLoading ? (
        // Show spinner while loading
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <h1>لوحة التحكم</h1>
          <p>عرض معلومات حول طلبات الخدمة وحالة إنجازها.</p>

          <Row>
            {_2orders.length < 0 &&
              _2orders.map((order) => (
                <Col key={order._id} md={4} className="mb-3">
                  <Card>
                    <Card.Header>
                      {order.customerName} - {order.city} - {order.time} -{" "}
                      {order.date}
                    </Card.Header>
                    <Card.Body>
                      <p>الطلب: {order.subService}</p>
                      <p>حالة الطلب: {order.status}</p>
                      {order.status === "In Progress" && (
                        <ProgressBar
                          now={order.progress}
                          label={`${order.progress}%`}
                          style={{ height: "30px" }}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              ))}

            <Col>
              <Card>
                <Card.Header>
                  <FontAwesomeIcon icon={faListAlt} /> طلب جديد
                </Card.Header>
                <Card.Body>
                  <Link to={`/servicelist`}>
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100 mt-4"
                    >
                      طلب خدمة جديدة
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="text-center mt-3">
              <Link to={`/customerdashboard/infoorders`}>
                <Card>
                  <Card.Header>
                    <FontAwesomeIcon icon={faInfo} /> الطلبات قيد الاستفسار
                  </Card.Header>
                  <Card.Body>
                    <h1>
                      {orders.length
                        ? orders.filter((order) => order.status === "استفسار")
                            .length
                        : 0}
                    </h1>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={3} className="mt-3">
              <Link to={`/customerdashboard/allorderlist`}>
                <Card>
                  <Card.Header>
                    <FontAwesomeIcon icon={faBox} /> عدد الطلبات الكلي
                  </Card.Header>
                  <Card.Body className="text-center">
                    <h1>{orders.length}</h1>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col md={3} className="mt-3">
              <Link to={`/customerdashboard/completeorders`}>
                <Card>
                  <Card.Header>
                    <FontAwesomeIcon icon={faClipboardCheck} /> الطلبات المكتملة
                  </Card.Header>
                  <Card.Body className="text-center">
                    <h1>
                      {
                        orders.filter((order) => order.status === "مكتملة")
                          .length
                      }
                    </h1>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col md={3} className="mt-3">
              <Link to={`/customerdashboard/inprogressorders`}>
                <Card>
                  <Card.Header>
                    <FontAwesomeIcon icon={faClock} /> الطلبات قيد التنفيذ
                  </Card.Header>
                  <Card.Body className="text-center">
                    <h1>
                      {
                        orders.filter((order) => order.status === "قيد التنفيذ")
                          .length
                      }
                    </h1>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col md={3} className="mt-3">
              <Link to={`/customerdashboard/${userId}/waitingorder`}>
                <Card>
                  <Card.Header>
                    <FontAwesomeIcon icon={faUserClock} /> بإنتظار الموافقة
                  </Card.Header>
                  <Card.Body className="text-center">
                    <h1>
                      {
                        orders.filter((order) => order.status === "جديدة")
                          .length
                      }
                    </h1>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={4} className="">
              <Card>
                <Card.Header>
                  <FontAwesomeIcon icon={faFileAlt} /> الملف الشخصي
                </Card.Header>
                <Card.Body>
                  <p>اسم المستخدم: {userProfile.name}</p>
                  <p>البريد الإلكتروني: {userProfile.email}</p>
                  <Link to={"/profile"}>
                    <Button variant="primary"> تعديل الملف الشخصي</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={8} className="mt-3">
              <Link>
                <Card>
                  <Link to={`/customerdashboard/orderlist`}>
                    <Card.Header>
                      <Row>
                        <Col>
                          <FontAwesomeIcon icon={faEnvelope} /> الرسائل
                          والاشعارات
                        </Col>
                        <Col className="text-start">
                          <a href="#!" style={{ marginRight: "5px" }}>
                            <MDBIcon fas icon="envelope" size="lg" />
                            {(messNum !== 0 || notReadOrNum !== 0) && (
                              <MDBBadge color="danger" notification pill>
                                {messNum + notReadOrNum}
                              </MDBBadge>
                            )}
                          </a>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <ListGroup>
                        {mess.length > 0 ? (
                          mess
                            .slice(0, 2)
                            .map((message, i) => (
                              <ListGroup.Item key={i}>
                                {message.message}
                              </ListGroup.Item>
                            ))
                        ) : (
                          <p>لا توجد رسائل</p>
                        )}
                      </ListGroup>
                    </Card.Body>
                  </Link>
                </Card>
              </Link>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default CustomerDashboard;

{
  /*
      <Row className="mt-4">
      <Col md={12}>
        <Card>
          <Card.Header>إحصائيات الطلبات خلال الأشهر السابقة</Card.Header>
          <Card.Body>
            <Row>
              {monthlyOrders.map((order) => (
                <Col key={order.month} md={4} className="mb-3">
                  <Card>
                    <Card.Body>
                      <h3>{order.month}</h3>
                      <p>الطلبات المكتملة: {order.completed}</p>
                      <p>الطلبات غير المكتملة: {order.incomplete}</p>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
            */
}

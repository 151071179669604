import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import moment from "moment";
import "rc-slider/assets/index.css";
import { getDocs, collection ,getDoc ,doc ,updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Link ,useNavigate} from "react-router-dom";
import useUser from "../hooks/useUser";
import { v4 as uuidv4 } from "uuid";
import { MDBSpinner } from "mdb-react-ui-kit";

const EngineeringOffers = ({ city, searchTerm }) => {
  const [isLoad, setIsLoad] = useState(false);
  const [data, setData] = useState([]);
  const { user, isLoading } = useUser();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoad(true);
    if(!isLoading) {

      async function fetchEngineeringData() {
        try {
          const querySnapshot = await getDocs(collection(db, "EngOffers"));
          const offersData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(offersData);
          setIsLoad(false);
        } catch (error) {
          console.error("Error fetching documents: ", error);
          setIsLoad(false);
        }
      }


      const fetchUserDataById = async () => {
        try {
          if (!isLoading && user) {
            const UserDoc = await getDoc(doc(db, "Users", user.uid));
            if (UserDoc.exists()) {
              setUserData(UserDoc.data());
            } else {
              console.error("No such document!");
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      
    fetchEngineeringData();
    fetchUserDataById();
    }
    

  }, [isLoading]);

  const [sortBy, setSortBy] = useState({
    latest: false,
    mostViewed: false,
    mostReplies: false,
    mostRated: false,
    mostOrdered: false,
    activeNow: false,
  });

  const [selectedCategories, setSelectedCategories] = useState({
    "خدمات الرخص التجارية": false,
    "خدمات البلدية": false,
    "استشارات هندسية": false,
  });

  const formatTimeAgo = (time) => {
    return moment(time).fromNow();
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleStartOrNavigateConversation = async (message , companyId , reciverName) => {
    const responseId = uuidv4();
    
    try {
      const userDocRef = doc(db, "Users", user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.error("User does not exist!");
        return;
      }
  
      const userData = userDoc.data();
      let conversations = userData.conversations || [];
  
      // البحث عن المحادثة الحالية إذا كانت موجودة
      let conversation = conversations.find(
        (conv) => conv.reciverId === companyId 
      );
     
      if(conversation)
      localStorage.setItem("conversationId", conversation.id);
      console.log(conversation)
      if (!conversation) {
        const conversationId = uuidv4();
        console.log("no")
        localStorage.setItem("conversationId", conversationId);
        const initialMessage = {
          id: uuidv4(),
          senderId: user.uid,
          senderName:user.displayName,
          reciverName:reciverName,
          reciverId: companyId,
          message: `مرحبا لدي استفسار بخصوص عرضك : "${message}"`,
          timestamp: new Date(),
        };
  
        conversation = {
          id: conversationId,
          senderId: user.uid,
          responseId: responseId,
          reciverId: companyId,
          customerId: user.uid,
          senderName:user.displayName,
          reciverName:reciverName,
          messages: [initialMessage],
        };
  
        // إضافة المحادثة الجديدة إلى قائمة المحادثات
        conversations.push(conversation);
    
        // تحديث مستند المستخدم
        await updateDoc(userDocRef, {
          conversations: conversations.length > 0 ? conversations : [] // تأكد من عدم إرسال قيم غير صالحة
        });
  
        // إضافة المحادثة الجديدة إلى مستند الشركة
        const companyDocRef = doc(db, "Users", companyId);
        const companyDoc = await getDoc(companyDocRef);
        if (companyDoc.exists()) {
          let companyConversations = companyDoc.data().conversations || [];
          companyConversations.push(conversation);
  
          await updateDoc(companyDocRef, {
            conversations: companyConversations.length > 0 ? companyConversations : [] // تأكد من عدم إرسال قيم غير صالحة
          });
        } else {
          console.error("Company document does not exist!");
        }
      }
  
      navigate(`/messages`);
    } catch (error) {
      console.error("Error handling conversation:", error);
    }
  };
  
  // تصفية البيانات بناءً على البحث والمدينة والفئات المحددة
  const filteredOffers = data
    .filter(
      (offer) =>
        offer.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!city || offer.city === city) &&
        (Object.values(selectedCategories).some((value) => value)
          ? Object.keys(selectedCategories).some(
              (category) =>
                selectedCategories[category] && offer.category === category
            )
          : true)
    )
    .sort((a, b) => {
      if (sortBy.latest) {
        return b.createdAt.seconds - a.createdAt.seconds;
      }
      if (sortBy.mostViewed) {
        return b.views - a.views;
      }
      if (sortBy.mostReplies) {
        return b.responses.length - a.responses.length;
      }
      if (sortBy.mostRated) {
        return b.rating - a.rating;
      }
      if (sortBy.mostOrdered) {
        return b.orderCount - a.orderCount;
      }
      if (sortBy.activeNow) {
        return a.status === "active" ? -1 : 1; // Prioritize active now offers
      }
      return 0;
    });

    if (isLoad) {
      return (
        <div className="text-center" style={{ marginTop: "300px" }}>
          {" "}
          <MDBSpinner
            style={{ width: "100px", height: "100px" }}
            grow
            role="status"
            className="me-2"
          />{" "}
        </div>
      );
    }

  
      
  return (
    <Row>
    <Col md={3} sm={12}>
    <div className="sidebar bg-light p-4 border rounded shadow-sm">
      <h3 className="mb-4 text-center border-bottom pb-2">التصنيف</h3>
  
      {/* فئة العروض */}
      <Form.Group controlId="categories" className="mb-4">
        <h4 className="fw-bold mb-3">فئة العروض</h4>
        <div className="d-flex flex-column gap-2">
          {Object.keys(selectedCategories).map((category) => (
            <Form.Check
              key={category}
              type="checkbox"
              id={category}
              label={category}
              checked={selectedCategories[category]}
              onChange={() => handleCategoryChange(category)}
              className="mb-2"
            />
          ))}
        </div>
      </Form.Group>
  
      {/* المميزات */}
      <Form.Group controlId="sortBy" className="mt-4">
        <h4 className="fw-bold mb-3">المميزات</h4>
        <div className="d-flex flex-column gap-2">
          <Form.Check
            type="radio"
            id="latest"
            name="sortBy"
            label="الأحدث"
            checked={sortBy.latest}
            onChange={() =>
              setSortBy({
                latest: true,
                mostViewed: false,
                mostReplies: false,
                mostRated: false,
                mostOrdered: false,
                activeNow: false,
              })
            }
            className="mb-2"
          />
          <Form.Check
            type="radio"
            id="mostViewed"
            name="sortBy"
            label="الأكثر مشاهدة"
            checked={sortBy.mostViewed}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: true,
                mostReplies: false,
                mostRated: false,
                mostOrdered: false,
                activeNow: false,
              })
            }
            className="mb-2"
          />
          <Form.Check
            type="radio"
            id="mostReplies"
            name="sortBy"
            label="الأكثر ردود"
            checked={sortBy.mostReplies}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: false,
                mostReplies: true,
                mostRated: false,
                mostOrdered: false,
                activeNow: false,
              })
            }
            className="mb-2"
          />
          <Form.Check
            type="radio"
            id="mostRated"
            name="sortBy"
            label="الأكثر تقييماً"
            checked={sortBy.mostRated}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: false,
                mostReplies: false,
                mostRated: true,
                mostOrdered: false,
                activeNow: false,
              })
            }
            className="mb-2"
          />
          <Form.Check
            type="radio"
            id="mostOrdered"
            name="sortBy"
            label="الأكثر طلباً"
            checked={sortBy.mostOrdered}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: false,
                mostReplies: false,
                mostRated: false,
                mostOrdered: true,
                activeNow: false,
              })
            }
            className="mb-2"
          />
          <Form.Check
            type="radio"
            id="activeNow"
            name="sortBy"
            label="المتصلون الآن"
            checked={sortBy.activeNow}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: false,
                mostReplies: false,
                mostRated: false,
                mostOrdered: false,
                activeNow: true,
              })
            }
            className="mb-2"
          />
        </div>
      </Form.Group>
    </div>
  </Col>
  

      <Col md={9} sm={12}>
        {filteredOffers.length > 0 ? (
          filteredOffers.map((offer) => (
            <div key={offer.id} className="offer-card p-3 border rounded mb-3">
              <Row>
                <Col>
                  <h3 className="mb-2">
                  <Link to={`/companyprofile/${offer.companyId}`}>{offer.companyName}</Link>
                  </h3>
                  <div className="text-muted mb-2">
                    {Array.from({ length: 5 }, (_, index) => {
                      return index < offer.rating ? (
                        <i
                          key={index}
                          className="bi bi-star-fill text-warning"
                        ></i>
                      ) : (
                        <i key={index} className="bi bi-star text-warning"></i>
                      );
                    })}
                    <div className="mt-3">
                    <h3>{offer.title}</h3>
                    <p>{offer.offerrDetails}</p>
                    </div>
                    
                  </div>
                </Col>
                <Col className="text-start" md={3}>
                 { userData ? userData.type === "customer" && 
                  <Button variant="primary" onClick={()=> handleStartOrNavigateConversation( offer.offerrDetails , offer.companyId , offer.companyName)}>تواصل معي</Button>
                :null}
                                  </Col>
              </Row>

              <p>{offer.description}</p>
            </div>
          ))
        ) : (
          <p>لا توجد عروض مطابقة للبحث.</p>
        )}
      </Col>
    </Row>
  );
};

export default EngineeringOffers;

import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import moment from "moment";
import 'moment/locale/ar';
import "rc-slider/assets/index.css";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../firebase"; // تأكد من أن لديك إعداد db في firebase.js
import { useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import { MDBSpinner } from "mdb-react-ui-kit";


const RealEstateRequests = ({filters, searchTerm, city }) => {
    const [sortBy, setSortBy] = useState({
      latest: false,
      mostViewed: false,
      mostReplies: false,
      activeNow: false,
    });
  
    const formatTimeAgo = (timestamp) => {
      const date = timestamp instanceof Date ? timestamp : timestamp?.toDate();
      moment.locale('ar');
      return moment(date).fromNow();
    };
    
  
    const [data, setData] = useState([])
    const [isLoad, setIsLoad] = useState(false);
  const { user, isLoading } = useUser();
   const [userData,setUserData] = useState(null)
   const navigate = useNavigate();

    useEffect(() => {
      setIsLoad(true);
      if(!isLoading) {
  
        const fetchUserDataById = async () => {
          try {
            if (!isLoading && user) {
              const UserDoc = await getDoc(doc(db, "Users", user.uid));
              if (UserDoc.exists()) {
                setUserData(UserDoc.data());
              } else {
                console.error("No such document!");
              }
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        };
    
        fetchUserDataById()
      }
     
      async function fetchRealStateReq() {
        try {
          const querySnapshot = await getDocs(collection(db, "RealStateOrders"));
          const requestsData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            docId: doc.id, // Add docId to the data
          }));
          setData(requestsData);
          setIsLoad(false);
        } catch (error) {
          console.error("Error fetching documents: ", error);
          setIsLoad(false);
        }
      }
  
       
      fetchRealStateReq();
  
    }, [isLoading]);
  
    const handleNavigateToOrderDetails = (docId) => {
      navigate(`/orderdetails/RealState/${docId}`); // Navigate to order details page with specific docId
    };

    const filteredRequests = data
      .filter(
        (request) =>
          request.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (!city || request.city === city)
      )
      .sort((a, b) => {
        if (sortBy.latest) {
          return new Date(b.time) - new Date(a.time);
        }
        if (sortBy.mostViewed) {
          return b.views - a.views;
        }
        if (sortBy.mostReplies) {
          return b.replies - a.replies;
        }
        if (sortBy.activeNow) {
          return 0; // Implement logic if needed
        }
        return 0;
      });
  
      
        if (isLoad) {
          return (
            <div className="text-center" style={{ marginTop: "300px" }}>
              {" "}
              <MDBSpinner
                style={{ width: "100px", height: "100px" }}
                grow
                role="status"
                className="me-2"
              />{" "}
            </div>
          );
        }

    return (
      <div>
      <Col md={9} sm={12}>
      {filteredRequests.length > 0 ? (
        filteredRequests.map((request) => (
          <div
            key={request.id}
            className="request-card p-3 border rounded mb-3"
          >
            <Row>
              <Col>
                <h3 className="mb-2">{request.title}</h3>
              </Col>
              <Col className="text-start" md={3}>
              {userData ? userData.type === 'company' &&
                <Button variant="primary" onClick={() => handleNavigateToOrderDetails(request.docId)}>تفاصيل الطلب</Button>
              :null}
                
              </Col>
            </Row>
  
            <p className="text-muted">
              <i className="bi bi-person-circle"></i> 
              {request.customerName} |{" "}
              <i className="bi bi-clock mx-2"></i>{" "}
              {formatTimeAgo(request.createdAt)} |{" "}
              <i className="bi bi-reply mx-2"></i> عدد الردود:{" "}
              {request.responses.length}
            </p>
            <p>{request.orderDetails}</p>
  
            {/* Left-aligned button */}
          </div>
        ))
      ) : (
        <p>لا توجد طلبات مطابقة للبحث.</p>
      )}
    </Col>
  
    <Col md={3}>
    <div className="sidebar bg-light p-4 border rounded">
      <h3 className="mb-4">التصنيف </h3>
      <Form.Group controlId="sortBy">
        <div className="d-flex flex-column">
          <Form.Check
            type="radio"
            id="latest"
            name="sortBy"
            label="الأحدث"
            checked={sortBy.latest}
            onChange={() =>
              setSortBy({
                latest: true,
                mostViewed: false,
                mostReplies: false,
                activeNow: false,
              })
            }
            className="mb-2"
          />
          <Form.Check
            type="radio"
            id="mostViewed"
            name="sortBy"
            label="الأكثر مشاهدة"
            checked={sortBy.mostViewed}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: true,
                mostReplies: false,
                activeNow: false,
              })
            }
            className="mb-2"
          />
          <Form.Check
            type="radio"
            id="mostReplies"
            name="sortBy"
            label="الأكثر ردود"
            checked={sortBy.mostReplies}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: false,
                mostReplies: true,
                activeNow: false,
              })
            }
            className="mb-2"
          />
          <Form.Check
            type="radio"
            id="activeNow"
            name="sortBy"
            label="المتصلون الآن"
            checked={sortBy.activeNow}
            onChange={() =>
              setSortBy({
                latest: false,
                mostViewed: false,
                mostReplies: false,
                activeNow: true,
              })
            }
            className="mb-2"
          />
        </div>
      </Form.Group>
    </div>
  </Col>
  
      </div>
    );
  };

  export default RealEstateRequests;
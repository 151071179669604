import React, { useEffect, useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { db, collection, addDoc, serverTimestamp } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import useUser from "../../hooks/useUser";

const EngOrder = () => {
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("الرياض");
  const [orderDetails, setOrderDetails] = useState("");
  const [category, setCategory] = useState("خدمات الرخص التجارية");
  const [file, setFile] = useState(null);
  const { user, isLoading } = useUser();
  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setCustomerName(user.displayName);
      setCustomerId(user.uid)
      console.log(user.uid)
    }
  }, [isLoading]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      let fileURL = "";

      if (file) {
        const storage = getStorage(); // إنشاء مرجع لتخزين Firebase
        const storageRef = ref(storage, `orders/${file.name}`);
        await uploadBytes(storageRef, file);
        fileURL = await getDownloadURL(storageRef);
      }

      await addDoc(collection(db, "EngOrders"), {
        customerName,
        customerId,
        title,
        city,
        orderDetails,
        category,
        fileURL, // رابط الملف المرفوع
        orderStatus: "Pending",
        responses: [],
        createdAt: serverTimestamp(),
      });

      alert("تم إنشاء الطلب بنجاح!");
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">إنشاء طلب جديد</h1>
      <Form onSubmit={handleSubmit} className="mx-auto p-4 border rounded shadow-sm bg-light" style={{ maxWidth: '600px' }}>
        <Form.Group controlId="customerName" className="mb-3">
          <Form.Label>اسم العميل</Form.Label>
          <Form.Control
            readOnly
            type="text"
            placeholder="أدخل اسمك"
            value={customerName}
            required
          />
        </Form.Group>

        <Form.Group controlId="title" className="mb-3">
          <Form.Label>عنوان الطلب</Form.Label>
          <Form.Control
            type="text"
            placeholder="أدخل عنوان الطلب"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="category" className="mb-3">
          <Form.Label>الفئة</Form.Label>
          <Form.Control
            as="select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="خدمات الرخص التجارية">خدمات الرخص التجارية</option>
            <option value="خدمات البلدية">خدمات البلدية</option>
            <option value="استشارات هندسية">استشارات هندسية</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="city" className="mb-3">
          <Form.Label>المدينة</Form.Label>
          <Form.Control
            as="select"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          >
            <option value="الرياض">الرياض</option>
            <option value="جدة">جدة</option>
            <option value="مكة">مكة المكرمة</option>
            <option value="المدينة المنورة">المدينة المنورة</option>
            <option value="الدمام">الدمام</option>
            <option value="الخبر">الخبر</option>
            <option value="الطائف">الطائف</option>
            <option value="القصيم">القصيم</option>
            <option value="تبوك">تبوك</option>
            <option value="أبها">أبها</option>
            <option value="جازان">جازان</option>
            <option value="نجران">نجران</option>
            <option value="حائل">حائل</option>
            <option value="الباحة">الباحة</option>
            <option value="عرعر">عرعر</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="orderDetails" className="mb-3">
          <Form.Label>تفاصيل الطلب</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="أدخل تفاصيل الطلب"
            value={orderDetails}
            onChange={(e) => setOrderDetails(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="file" className="mb-3">
          <Form.Label>إرفاق ملف</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100" disabled={uploading}>
          {uploading ? "جارٍ رفع الطلب..." : "إنشاء الطلب"}
        </Button>
      </Form>
    </Container>
  );
};

export default EngOrder;

import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Navbar,
  ProgressBar,
  ListGroup,
  Nav,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Styles from "../../css/AdminDashBoard.module.css";
import {
  faBox,
  faClipboardCheck,
  faClock,
  faEnvelope,
  faFileAlt,
  faListAlt,
  faHome,
  faUser,
  faFile,
  faInfo,
  faServer,
  faUserCheck,
  faUserFriends,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useUser from "../../hooks/useUser";
import { MDBBadge, MDBIcon, MDBSpinner } from "mdb-react-ui-kit";
import NotFoundPage from "../NotFoundPage";

const EmployeeDashboard = () => {
  const [orders, setOrders] = useState([]);
  const [adminProfile, setAdminProfile] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);
  const [_3orders, set_3orders] = useState([]);
  const [_3serviceList, set_3serviceList] = useState([]);
  const { user, isLoading } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const getData = async () => {
    if (isAdmin()) {
      // console.log("admin")
    } else {
      // console.log("not admin")
    }
    setLoading(true)
    const token = await user.getIdToken();
    const headers = token ? { authtoken: token } : {};

    const resp = await axios.get(`/api/admin`, { headers });
    setNewUsers(resp.data.Users);
    setLoading(false)
    if (resp.data.orders.length >= 2) {
      const [obj1, obj2] = resp.data.orders;
      const _3order = [obj1, obj2];
      set_3orders(_3order);
    } else {
      const [obj1] = resp.data.orders;
      const _3order = [obj1];
      set_3orders(_3order);
    }

    setOrders(resp.data.orders);
    //get servicelist

    setAdminProfile();
  };

  useEffect(() => {
    if (!isLoading && user)
      isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getData();
        } else {
          setCheckIsAdmin(false);
        }
      });
  }, [isLoading]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        {" "}
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />{" "}
      </div>
    );
  }

  return (
    <div className="container">
      <Row>
        <Col>
          {/* Main Content */}
          <div className="ml-lg-5">
            <h1>لوحة التحكم</h1>
            <p>عرض معلومات حول طلبات الخدمة وحالة إنجازها.</p>

            {/* Remaining content */}
            <Row>
              <Col md={4} className="text-center mt-3">
                <Link to={`/dashboard/neworders`}>
                  <Card>
                    <Card.Header>
                      <FontAwesomeIcon icon={faListAlt} /> الطلبات الجديدة
                    </Card.Header>
                    <Card.Body>
                      {orders.length ? (
                        <Badge
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "40px",
                          }}
                          bg={`${
                            orders.filter((order) => order.status === "جديدة")
                              .length
                              ? "danger"
                              : "primary"
                          }`}
                        >
                          <h1>
                            {
                              orders.filter((order) => order.status === "جديدة")
                                .length
                            }
                          </h1>
                        </Badge>
                      ) : (
                        <h1>0</h1>
                      )}
                    </Card.Body>
                  </Card>
                </Link>
              </Col>

              {_3orders.map((order, i) => (
                <Col key={order.id} md={4} className="mb-3 mt-3">
                  <Link
                    key={i}
                    to={{
                      pathname: `/dashboard/order/${btoa(
                        JSON.stringify(order._id)
                      )}`,
                    }}
                  >
                    <Card>
                      <Card.Header>
                        {order.customerName} - {order.city} - {order.time}
                      </Card.Header>
                      <Card.Body>
                        <p>الطلب: {order.subService}</p>
                        <p>حالة الطلب: {order.status}</p>
                        {order.status === "قيد التنفيذ" && (
                          <ProgressBar
                            now={order.progress}
                            label={`${order.progress}%`}
                            style={{ height: "30px" }}
                          />
                        )}
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>

            <Row className="mt-4">
              <Col md={3} className="text-center mt-3">
                <Link to={`/dashboard/allorders`}>
                  <Card>
                    <Card.Header>
                      <FontAwesomeIcon icon={faBox} /> عدد الطلبات الكلي
                    </Card.Header>
                    <Card.Body>
                      <h1>{orders.length ? orders.length : 0}</h1>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
              <Col md={3} className="text-center mt-3">
                <Link to={`/dashboard/completeorders`}>
                  <Card>
                    <Card.Header>
                      <FontAwesomeIcon icon={faClipboardCheck} /> المكتملة
                    </Card.Header>
                    <Card.Body>
                      <h1>
                        {orders.length
                          ? orders.filter((order) => order.status === "مكتملة")
                              .length
                          : 0}
                      </h1>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
              <Col md={3} className="text-center mt-3">
                <Link to={`/dashboard/inprogressorders`}>
                  <Card>
                    <Card.Header>
                      <FontAwesomeIcon icon={faClock} /> قيد التنفيذ
                    </Card.Header>
                    <Card.Body>
                      <h1>
                        {orders.length
                          ? orders.filter(
                              (order) => order.status === "قيد التنفيذ"
                            ).length
                          : 0}
                      </h1>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
              <Col md={3} className="text-center mt-3">
                <Link to={`/dashboard/infoorders`}>
                  <Card>
                    <Card.Header>
                      <FontAwesomeIcon icon={faInfo} /> قيد الاستفسار
                    </Card.Header>
                    <Card.Body>
                      <h1>
                        {orders.length
                          ? orders.filter((order) => order.status === "استفسار")
                              .length
                          : 0}
                      </h1>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            </Row>

            <Row className="mt-4" style={{ marginBottom: "100px" }}>
              <Col md={5}>
                <Card>
                  <Card.Header>
                    <FontAwesomeIcon icon={faFileAlt} /> الملف الشخصي
                  </Card.Header>
                  <Card.Body>
                    <p>اسم المستخدم: {!isLoading && user.displayName}</p>
                    <p>البريد الإلكتروني: {!isLoading && user.email}</p>
                    <Link to={"/profile"}>
                      <Button variant="primary"> تعديل الملف الشخصي</Button>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={7} className="mt-3">
                <Link>
                  <Card>
                    <Link to={`/dashboard/newusers`}>
                      <Card.Header>
                        <Row>
                          <Col md={9} sm={9}>
                            <FontAwesomeIcon icon={faUserFriends} /> طلبات
                            الانضمام
                          </Col>
                          <Col md={3} sm={3} className="text-start">
                            <a href="#!">
                              <MDBIcon fas icon="user-plus" size="lg" />
                              {newUsers.length > 0 && (
                                <MDBBadge color="danger" notification pill>
                                  {newUsers.length}
                                </MDBBadge>
                              )}
                            </a>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <ListGroup>
                          {newUsers.length > 0 ? (
                            newUsers.slice(0, 3).map((user, i) => (
                              <ListGroup.Item key={i}>
                                طلب <strong>{user.name}</strong> الانضمام للمنصة
                              </ListGroup.Item>
                            ))
                          ) : (
                            <p>لا يوجد طلبات انضمام جديدة.</p>
                          )}
                        </ListGroup>
                      </Card.Body>
                    </Link>
                  </Card>
                </Link>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* Mobile Navbar */}
      <Navbar
        bg="primary"
        className={`fixed-bottom d-md-inline-flex justify-content-around ${Styles.navlink}`}
        style={{ height: 60, color: "traspernt", marginBottom: "0px" }}
      >
        <Nav.Link href="#home">
          <Link className={Styles.navlink} to={"/dashboard/userslist"}>
            <FontAwesomeIcon icon={faUser} size="lg" /> المستخدمين
          </Link>
        </Nav.Link>
        <Nav.Link href="#users">
          <Link className={Styles.navlink} to={"/dashboard/reports"}>
            <FontAwesomeIcon icon={faFileAlt} size="lg" /> التقارير
          </Link>
        </Nav.Link>
        <Nav.Link href="#services" className={Styles.navlink}>
          <Link
            className={Styles.navlink}
            style={{ color: "white !important" }}
            to="/dashboard/services"
          >
            <FontAwesomeIcon icon={faTasks} size="lg" /> المهام 
          </Link>
        </Nav.Link>
      </Navbar>
    </div>
  );
};

export default EmployeeDashboard;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import moment from "moment";
import { Link } from "react-router-dom";
import EngineeringRequests from "../component/EngineeringRequests";
import EngineeringOffers from "../component/EngineeringOffers";
import RealEstateRequests from "../component/RealEstateRequests";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, setDoc , getDocs, addDoc , getDoc} from "firebase/firestore";
import OtherOffers from "../component/OtherOffers";
import OtherOrders from "../component/OtherOrders";
import RealEstateOffers from "../component/RealStateOffers";
import EngineeringMembers from "./company/Eng/EngineeringMembers";
import RealStateMembers from "./company/RealEstate/RealStateMembers";

// استبدل القيم هنا بقيم التكوين الخاصة بمشروع Firebase الخاص بك
const firebaseConfig = {
  apiKey: "AIzaSyCHf1sAy7wB4CIGBw-lsaI4DaCXOXuLwNA",
  authDomain: "my-react-blog-191fa.firebaseapp.com",
  projectId: "my-react-blog-191fa",
  storageBucket: "my-react-blog-191fa.appspot.com",
  messagingSenderId: "821981323548",
  appId: "1:821981323548:web:f85ed9cf7518f6c4ba9ad9",
  measurementId: "G-JZZWK4GFWJ"
};

// تهيئة Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);



// مكون فرعي لعروض العقارات

// مكون فرعي لطلبات العقارات




const Home = () => {
  const [selectedSection, setSelectedSection] = useState("Realestate");
  const [viewType, setViewType] = useState("offers");
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [companyType, setCompanyType] = useState('Realestate')
  const [city, setCity] = useState(""); // New state for city filter
  
  
const GoToCompanies = () => {
  setSelectedSection('members')
  if(selectedSection === 'members') {
    console.log(selectedSection)
    console.log(companyType)
  }
  
}
 useEffect(() => {

 },[])

 
  return (
    <Container>
      {/* Filter Buttons */}
      <Row className="my-3 text-center">
        <Col>
          <Button
            variant="primary"
            className="mx-2"
            onClick={() => {setSelectedSection("Realestate") ; setCompanyType('Realestate'); }}
          >
            عروض العقارات
          </Button>
          <Button
            variant="primary"
            className="mx-2"
            onClick={() => {setSelectedSection("مواد البناء"); setCompanyType('Engineering');}}
          >
            عروض مواد البناء
          </Button>
          <Button
          variant="primary"
          className="mx-2"
          onClick={() => {
            setSelectedSection("Engineering");
            setCompanyType('Engineering');
          }}
        >
          عروض الهندسية
        </Button>
        
          <Button
            variant="primary"
            className="mx-2"
            onClick={() => setSelectedSection("OtherOffers") }
          >
            عروض اخرى
          </Button>
        </Col>
      </Row>

      {/* Search Bar and Filter Buttons */}
      <Row className="my-3 text-center">
        <Col md={2}>
          <Button variant="outline-secondary">بحث بالخريطة</Button>
        </Col>
        <Col md={6}>
          <InputGroup>
            <Form.Control
              placeholder="بحث..."
              style={{ height: "100%" }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col md={2} className="text-start">
        
          <Button
            variant="primary"
            onClick={() =>
              GoToCompanies()
            }
          >
           اسماء الشركات
          </Button>
        </Col>

        <Col md={2} className="text-end">
          <Button
            variant="primary"
            onClick={() =>
              setViewType(viewType === "offers" ? "requests" : "offers")
            }
          >
            {viewType === "offers" ? "طلبات" : "عروض"}
          </Button>
        </Col>

        
      </Row>

      {/* City Filter */}
      <Row className="my-3 text-center">
        <Col>
          <Button
            variant={city === "" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "" ? "" : "")}
          >
            كل المدن
          </Button>
          <Button
            variant={city === "الرياض" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "الرياض" ? "" : "الرياض")}
          >
            الرياض
          </Button>
          <Button
            variant={city === "مكة" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "مكة" ? "" : "مكة")}
          >
            مكة
          </Button>
          <Button
            variant={city === "حائل" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "حائل" ? "" : "حائل")}
          >
            حائل
          </Button>
          <Button
            variant={city === "جدة" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "جدة" ? "" : "جدة")}
          >
            جدة
          </Button>
          <Button
            variant={city === "الدمام" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "الدمام" ? "" : "الدمام")}
          >
            الدمام
          </Button>
          <Button
            variant={city === "المدينة المنورة" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() =>
              setCity(city === "المدينة المنورة" ? "" : "المدينة المنورة")
            }
          >
            المدينة المنورة
          </Button>
          <Button
            variant={city === "الطائف" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "الطائف" ? "" : "الطائف")}
          >
            الطائف
          </Button>
          <Button
            variant={city === "أبها" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "أبها" ? "" : "أبها")}
          >
            أبها
          </Button>
          <Button
            variant={city === "بريدة" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "بريدة" ? "" : "بريدة")}
          >
            بريدة
          </Button>
          <Button
            variant={city === "تبوك" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "تبوك" ? "" : "تبوك")}
          >
            تبوك
          </Button>
          <Button
            variant={city === "نجران" ? "primary" : "outline-primary"}
            className="mx-1"
            onClick={() => setCity(city === "نجران" ? "" : "نجران")}
          >
            نجران
          </Button>
        </Col>
      </Row>

      <Row className="mt-5">
        {selectedSection === "Realestate" && (
          <div>
            {viewType === "offers" && (
              <RealEstateOffers
                filters={filters}
                searchTerm={searchTerm}
                city={city}
              />
            )}
            {viewType === "requests" && (
              <RealEstateRequests
                filters={filters}
                searchTerm={searchTerm}
                city={city}
              />
            )}
          </div>
        )}

        {selectedSection === "Engineering" && (
          <div>
            {viewType === "offers" && (
              <EngineeringOffers
                filters={filters}
                searchTerm={searchTerm}
                city={city}
              />
            )}
            {viewType === "requests" && (
              <EngineeringRequests
                filters={filters}
                searchTerm={searchTerm}
                city={city}
              />
            )}
          </div>
        )}

        {selectedSection === "OtherOffers" && (
          <div>
            {viewType === "offers" && (
              <OtherOffers
                filters={filters}
                searchTerm={searchTerm}
                city={city}
              />
            )}
            {viewType === "requests" && (
              <OtherOrders
                filters={filters}
                searchTerm={searchTerm}
                city={city}
              />
            )}
          </div>
        )}

        {selectedSection === "members" && (
          <div>
            {companyType === "Engineering" && (
              <EngineeringMembers
                searchTerm={searchTerm}
                city={city}
              />
            )}
            {companyType === "Realestate" && (
              <RealStateMembers
                searchTerm={searchTerm}
                city={city}
              />
            )}
          </div>
        )}
      </Row>
    </Container>
  );
};

export default Home;

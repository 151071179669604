import React, { useEffect, useState } from "react";
import { Navbar, Nav, Badge, Dropdown, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { db, getDoc, doc } from "../firebase";
import useUser from "../hooks/useUser";
import { BsEnvelope } from "react-icons/bs";

const NavBar = () => {
  const [totalUnreadResponses, setTotalUnreadResponses] = useState(0);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [userData, setUserData] = useState(null); // حالة لتخزين بيانات المستخدم
  const navigate = useNavigate();
  const { user, isLoading, setUser } = useUser(); // تأكد من أن useUser يدير حالة المستخدم

  useEffect(() => {
    if (!isLoading && user) {
      const fetchUserDataById = async () => {
        try {
          const UserDoc = await getDoc(doc(db, "Users", user.uid));
          if (UserDoc.exists()) {
            const data = UserDoc.data();
            setUserData(data); // تحديث حالة userData
            const userUid = user.uid;

            // حساب عدد الرسائل غير المقروءة
            const unreadMessages = data.conversations.flatMap(
              (conversation) =>
                conversation.messages.filter(
                  (message) =>
                    !message.isReaded && message.reciverId === userUid
                )
            );
            setUnreadMessagesCount(unreadMessages.length);
          } else {
            console.error("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      const fetchOrders = async () => {
        try {
          // Fetch Engineering Orders
          const engQuery = query(
            collection(db, "EngOrders"),
            where("customerId", "==", user.uid)
          );
          const engQuerySnapshot = await getDocs(engQuery);
          const engOrders = engQuerySnapshot.docs.map((doc) => ({
            ...doc.data(),
            docId: doc.id,
          }));

          // Count unread responses for Engineering Orders
          const unreadEng = engOrders.reduce((acc, order) => {
            return (
              acc + order.responses.filter((resp) => !resp.isReaded).length
            );
          }, 0);

          // Fetch Real Estate Orders
          const realQuery = query(
            collection(db, "RealStateOrders"),
            where("customerId", "==", user.uid)
          );
          const realQuerySnapshot = await getDocs(realQuery);
          const realOrders = realQuerySnapshot.docs.map((doc) => ({
            ...doc.data(),
            docId: doc.id,
          }));

          // Count unread responses for Real Estate Orders
          const unreadReal = realOrders.reduce((acc, order) => {
            return (
              acc + order.responses.filter((resp) => !resp.isReaded).length
            );
          }, 0);

          // Fetch Other Orders
          const otherQuery = query(
            collection(db, "OtherOrders"),
            where("customerId", "==", user.uid)
          );
          const otherQuerySnapshot = await getDocs(otherQuery);
          const otherOrders = otherQuerySnapshot.docs.map((doc) => ({
            ...doc.data(),
            docId: doc.id,
          }));

          // Count unread responses for Other Orders
          const unreadOther = otherOrders.reduce((acc, order) => {
            return (
              acc + order.responses.filter((resp) => !resp.isReaded).length
            );
          }, 0);

          // Calculate the total unread responses
          const totalUnreadResponses = unreadEng + unreadReal + unreadOther;
          setTotalUnreadResponses(totalUnreadResponses);

          // Fetch user data to calculate unread messages count
          fetchUserDataById();
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      };

      fetchOrders();
    }
  }, [isLoading, user]);

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      setUser(null); // تعيين حالة المستخدم إلى null بعد تسجيل الخروج
      navigate("/"); // توجيه المستخدم إلى الصفحة الرئيسية
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleMessagesClick = () => {
    navigate("/messages");
  };

  return (
    <Navbar bg="light" expand="lg" className="mb-4">
      <Navbar.Brand href="/">منصة مجال البناء</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link href="/">الصفحة الرئيسية</Nav.Link>
        </Nav>

        <Nav>
          {user && (
            <Dropdown className="mx-3 mt-3">
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-custom-components"
                className="p-0 d-flex align-items-center position-relative border-0 bg-transparent"
              >
                <i
                  className="bi bi-plus-lg"
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                ></i>
              </Dropdown.Toggle>
              {userData && userData.type === "customer" && (
                <Dropdown.Menu align="center" style={{ minWidth: "220px" }}>
                  <Dropdown.Item>
                    <Link to={"/engorder"}>اضف طلب هندسي</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to={"/addrealstateorder"}>اضف طلب عقار</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to={"/"}>اضف طلب مواد بناء</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to={"/addotherorder"}>طلبات اخرى</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              )}
              {userData && userData.type === "company" && (
                <Dropdown.Menu align="start" style={{ minWidth: "220px" }}>
                  <Dropdown.Item>
                    <Link
                      to={
                        userData.category === 'هندسية'
                          ? "/addengoffer"
                          : userData.category === 'عقارات'
                          ? "/addrealstateoffer"
                          : '/'
                      }
                    >
                      اضف عروض {userData.category}
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to={"/addotheroffer"}>اضف عروض اخرى</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              )}
            </Dropdown>
          )}

          {user && (
            <Nav.Link
              onClick={handleMessagesClick}
              style={{ position: "relative" }}
              className="mx-3 mt-1"
            >
              <BsEnvelope size={34} />
              {unreadMessagesCount > 0 && (
                <Badge
                  pill
                  bg="danger"
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-10px",
                  }}
                >
                  {unreadMessagesCount}
                </Badge>
              )}
            </Nav.Link>
          )}

          {user && (
            <Nav.Link
              onClick={() => {
                if (userData && userData.type === "customer") {
                  navigate('/profile');
                } else if (userData && userData.type === "company") {
                  navigate(`/companyprofile/${user.uid}`);
                }
              }}
              style={{ position: "relative" }}
              className="mx-3"
            >
              <Image
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                alt="Profile"
                roundedCircle
                width={40}
                height={40}
              />
              {totalUnreadResponses > 0 && (
                <Badge
                  pill
                  bg="danger"
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-10px",
                  }}
                >
                  {totalUnreadResponses}
                </Badge>
              )}
            </Nav.Link>
          )}

          <Dropdown className="mx-3 mt-2">
            <Dropdown.Toggle
              as={Nav.Link}
              id="dropdown-custom-components"
              className="p-0 d-flex align-items-center position-relative border-0 bg-transparent"
            >
              <i
                className="bi bi-caret-down-fill"
                style={{ fontSize: "19px", fontWeight: "bold" }}
              ></i>
            </Dropdown.Toggle>
            {user ? (
              <Dropdown.Menu align="start" style={{ minWidth: "220px" }}>
                <Dropdown.Item className="pt-3 pb-3 ps-4 pe-3">
                  <Link
                    onClick={handleLogout} // تنفيذ تسجيل الخروج عند الضغط
                  >
                    تسجيل خروج
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            ) : (
              <Dropdown.Menu align="start" style={{ minWidth: "220px" }}>
                <Dropdown.Item className="pt-3 pb-3 ps-4 pe-3">
                  <Link to={"/login"}>تسجيل دخول</Link>
                </Dropdown.Item>
                <Dropdown.Item className="pt-3 pb-3 ps-4 pe-3">
                  <Link to={"/login"}>انشاء حساب فرد</Link>
                </Dropdown.Item>
                <Dropdown.Item className="pt-3 pb-3 ps-4 pe-3">
                  <Link to={"/login"}>انشاء حساب شركة</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
